export const paymentFields = {
  CARD_CVV: "cardCVV",
  CARD_EXP_DATE: "cardExpDate",
  CARD_NUMBER: "cardNumber",
  CARD_POSTAL_CODE: "cardPostalCode",
};

export const paymentOptions = {
  inStore: "in-store",
  online: "online",
};

export const componentIds = {
  globalPaymentErrorMessageId: "global-payment-error-message",
};

export const INDIVIDUAL_PROVINCE_STATE_AGE_REQUIREMENTS: {
  [provence: string]: number;
} = {
  ab: 18,
  alberta: 18,
  ok: 18,
  oklahoma: 18,
  qc: 21,
  quebec: 21,
};

export const CANADA_AGE_REQUIREMENT = 19;
export const US_AGE_REQUIREMENT = 21;
