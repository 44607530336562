import React from "react";

import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";

export const AdvertisingLink = () => {
  const countryCode = useDomainCountryCode();
  const successUrl =
    countryCode === "CA"
      ? "https://success.leafly.ca"
      : "https://success.leafly.com/biz";
  const { publishEvent } = useEventTracker();

  return (
    <a
      href={`${successUrl}?utm_source=site_header&utm_medium=header&utm_campaign=sell_on_leafly&utm_content=SOL`}
      className="hidden lg:block flex-shrink-0 text-xs mx-xxl underline"
      onClick={() =>
        publishEvent({
          action: "click",
          category: "header",
          label: "advertise on leafly",
        })
      }
    >
      advertise on Leafly
    </a>
  );
};
