import serverConfig from "config/server";
import { isLocalDev } from "utils/isLocalDev";

const DEFAULT_COUNTRY_CODE = "US";

/**
 * A headers-like object, regardless of whether this is an actual instance
 * of IncomingHttpHeaders or an object that contains the same value type.
 * This allows us to us this function in a few different places where request
 * headers might take on different identities.
 */
type HeadersLike = NodeJS.Dict<string | string[]>;

export const getCountryCodeHeader = (headers: HeadersLike): string => {
  if (isLocalDev()) {
    return serverConfig.developmentDomainCountryCode;
  }

  const countryCodeHeader = headers["x-country-code"] || DEFAULT_COUNTRY_CODE;

  return Array.isArray(countryCodeHeader)
    ? countryCodeHeader[0]
    : countryCodeHeader;
};
