import React from "react";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";

import { CANCEL_REQUEST_LOCATION } from "redux/reducers/location";
import { getShouldOpenLocationModal } from "redux/selectors/location";

import { SidebarModal } from "./SidebarModal";

// This is being dynamically loaded so that any third party
// google maps scripts are not retrieved unless the user
// explicitly opens the location picker
const LocationPicker = dynamic(() => import("./LocationPicker"));

export const LocationPickerModal = ({
  returnFocusRef,
}: {
  returnFocusRef: React.RefObject<HTMLElement>;
}) => {
  const dispatch = useDispatch();
  const shouldOpen = useSelector(getShouldOpenLocationModal);

  const closeSidebarModal = () => {
    dispatch({
      type: CANCEL_REQUEST_LOCATION,
    });
  };

  return (
    <SidebarModal
      open={shouldOpen}
      onCloseClick={closeSidebarModal}
      returnFocusRef={returnFocusRef}
    >
      {shouldOpen && <LocationPicker />}
    </SidebarModal>
  );
};
