"use client";

import { ComponentProps } from "react";
import Link from "next/link";

import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

export const FooterLink: React.FC<
  ComponentProps<typeof Link> & { trackingLabel: string }
> = ({ trackingLabel, ...props }) => {
  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  const track = createPublishFooterClickEventFunction(trackingLabel);

  return (
    <Link
      {...props}
      onClick={(...args) => {
        props.onClick?.(...args);
        track();
      }}
    />
  );
};
