export const STRAIN_USER_REVIEW_DISCLAIMER =
  "These results are based on user reviews and are not a substitute for professional medical advice.";
export const STRAIN_MEDICAL_DISCLAIMER_US =
  "This info is sourced from our readers and is not a substitute for professional medical advice. Seek the advice of a health professional before using cannabis for a medical condition.";
export const STRAIN_MEDICAL_DISCLAIMER_CA =
  "Information about effects is sourced from Leafly customer reviews. Cannabis affects individuals differently, so you may experience different effects. Customer-reported information is not a substitute for medical advice. Always seek the advice of a health professional before using cannabis for a medical condition.";
export const DISPENSARY_MEDICAL_DISCLAIMER =
  '"(1) Marijuana has intoxicating effects and may be habit forming and addictive."; (2) "Marijuana impairs concentration, coordination, and judgment. Do not operate a vehicle or machinery under its influence."; (3) "There are health risks associated with consumption of marijuana."; (4) "For use only by adults twenty-one and older. Keep out of the reach of children."; (5) "Marijuana should not be used by women who are pregnant or breast feeding."';
export const FOOTER_MEDICAL_DISCLAIMER_US =
  "* Statements made on this website have not been evaluated by the U.S. Food and Drug Administration. These products are not intended to diagnose, treat, cure or prevent any disease. Information provided by this website or this company is not a substitute for individual medical advice.";
export const FOOTER_MEDICAL_DISCLAIMER_CA =
  "The material provided on Leafly is intended for educational and informational purposes only. Leafly is not engaged in rendering medical service or advice and the information provided is not a substitute for a professional medical opinion. If you have a medical problem, please contact a qualified health professional.";
export const UNCLAIMED_INFO_LISTING_DISCLAIMER =
  "This profile either has not been claimed by the business owner or is not currently active on Leafly. All contents and information (including business hours, operating status, and licensing information) are provided for informational purposes only and are not guaranteed to be up-to-date or complete.";
