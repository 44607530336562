import { configureStore, ThunkDispatch } from "@reduxjs/toolkit";

import rootReducer, { AllActions, RootState } from "./reducers/rootReducer";

export const makeStore = () =>
  configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    reducer: rootReducer,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunkDispatch = ThunkDispatch<RootState, string, AllActions>;
