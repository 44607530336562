"use client";

import { trackEvent, trackNonInteractionEvent } from "@leafly-com/web-utils";
import { useStore } from "react-redux";

import { Action, Category } from "constants/events";
import { RootState } from "redux/reducers/rootReducer";
import { getUserId } from "redux/selectors/user";
import { AppStore } from "redux/store";
import { Dimension } from "utils/trackEvent";

import { AllGtmEvents } from "../analytics/gtm";

const getCustomDimensionsFromStore = (state: RootState) => {
  return {
    userId: getUserId(state),
  };
};

export const useEventTracker = () => {
  const store = useStore<AppStore>();

  return {
    publishEvent: (event: AllGtmEvents) => {
      const { action, category, label, ...customDimensions } = event;

      /*
       * Non-interaction events are identified separately
       * by a different GTM Event name - which is done by calling a different function.
       * Non-interaction events are important to distinguish between because it has an impact on bounce-rate:
       * https://support.google.com/analytics/answer/1033068#NonInteractionEvents&zippy=%2Cin-this-article
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      const trackingFunction = (event as any).nonInteractionEvent
        ? trackNonInteractionEvent
        : trackEvent;

      trackingFunction(category, action, label, {
        ...getCustomDimensionsFromStore(
          store.getState() as unknown as RootState,
        ),
        ...customDimensions,
      });
    },
    trackEvent: (
      category: Category,
      action: Action,
      label?: string,
      customDimensions?: Dimension,
      nonInteractionEvent = false,
    ) =>
      (nonInteractionEvent ? trackNonInteractionEvent : trackEvent)(
        category,
        action,
        label,
        {
          ...getCustomDimensionsFromStore(
            store.getState() as unknown as RootState,
          ),
          ...(customDimensions || {}),
        },
      ),
    trackNonInteractionEvent: (
      category?: Category | string | undefined,
      action?: Action | string | undefined,
      label?: string,
      customDimensions?: Record<
        string,
        number | string | boolean | undefined | null
      >,
    ) =>
      trackNonInteractionEvent(category, action, label, {
        ...getCustomDimensionsFromStore(
          store.getState() as unknown as RootState,
        ),
        ...(customDimensions || {}),
      }),
  };
};
