"use client";

import React, { useRef, useState } from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

import { InformationalModal } from "components/botanic/Modal";

/**
 * The current version of @typeform/embed-react doesn't appear to tree-shake
 * well, adding about 15kb gzipped to the primary app bundle. We can avoid
 * the performance impact by loading it dynamically. It's potentially worth
 * revisiting when this package is updated to the latest version.
 */
const Widget = dynamic(() =>
  import("@typeform/embed-react").then((typeform) => typeform.Widget),
);

export default function FeedbackSurvey({ surveyId }: { surveyId: string }) {
  const { publishEvent } = useEventTracker();
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef(null);
  const pathname = usePathname();

  return (
    <div className="text-center text-white bg-default text-sm py-md">
      <button
        className="cursor-pointer"
        onClick={() => setIsVisible(true)}
        ref={buttonRef}
      >
        Website feedback?&nbsp;&nbsp;
        <span className="underline">let Leafly know</span>
      </button>

      {isVisible && (
        <InformationalModal
          expository
          onDismissed={() => setIsVisible(false)}
          returnFocusRef={buttonRef}
          title="Leafly Customer Survey"
        >
          {({ dismiss }) => (
            <Widget
              // eslint-disable-next-line jsx-a11y/no-autofocus -- the content of the embed says "press enter to continue" which implies the need to auto-focus to match user expectations
              autoFocus
              className="h-full md:h-[400px]"
              id={surveyId}
              inlineOnMobile
              shareGaInstance={true}
              hidden={{
                source: pathname || "",
              }}
              onSubmit={() => {
                publishEvent({
                  action: Action.click,
                  category: Category.feedbackSurvey,
                  label: "feedback submitted",
                });

                setTimeout(() => {
                  dismiss();
                }, 3000);
              }}
              onReady={() => {
                publishEvent({
                  action: Action.click,
                  category: Category.feedbackSurvey,
                  label: "open survey",
                });
              }}
            />
          )}
        </InformationalModal>
      )}
    </div>
  );
}
