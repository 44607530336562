"use client";

import dynamic from "next/dynamic";

const ChatContainer = dynamic(() => import("./ChatContainer"), { ssr: false });

/**
 * This component only exists to allow the ChatContainer component to be
 * loaded on the client only. This is an optimization for Pages Router only,
 * and it may be advantageous to make more of this interface a server
 * component when more of the application has been moved to App Router.
 */
const Chat = () => <ChatContainer />;

export default Chat;
