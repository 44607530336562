import React from "react";

import useDomainCountryCode from "hooks/useDomainCountryCode";

import { FooterLink } from "./FooterLink";
import { ResponsiveAccordion } from "./ResponsiveAccordion";

export const BusinessSolutions: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  const isCanada = useDomainCountryCode() === "CA";

  return (
    <ResponsiveAccordion
      label="Business Solutions"
      defaultExpanded={true}
      isResponsive={isResponsive}
    >
      <ul className="text-sm text-green font-bold">
        {isCanada ? (
          <>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.ca?utm_source=dispensary_link&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=SOL"
                trackingLabel="list your store"
              >
                List your store
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.ca?utm_source=brands_link&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=SOL"
                trackingLabel="list your brand"
              >
                List your brand
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.ca/labs"
                trackingLabel="certify your lab"
              >
                Lab partners
              </FooterLink>
            </li>
          </>
        ) : (
          <>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.com/retail?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_store"
                trackingLabel="list your store"
              >
                List your store
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.com/cbd?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_cbd_store"
                trackingLabel="list your cbd store"
              >
                List your CBD store
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.com/brands?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_brand"
                trackingLabel="list your brand"
              >
                List your brand
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.com/doctors?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=list_your_practice"
                trackingLabel="list your practice"
              >
                List your practice
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://success.leafly.com/labs?utm_source=site_footer&utm_medium=footer&utm_campaign=sell_on_leafly&utm_content=certify_your_lab"
                trackingLabel="certify your lab"
              >
                Certify your lab
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://business.leafly.com/"
                trackingLabel="business log in"
              >
                Business log in
              </FooterLink>
            </li>
          </>
        )}
      </ul>
    </ResponsiveAccordion>
  );
};
