import React from "react";
import { Transition } from "@headlessui/react";

import { BASE_Z_INDEX } from "./ModalContext";

const Background: React.FC<{ inProp: boolean }> = ({ inProp }) => (
  <Transition
    className="fixed bottom-0 left-0 right-0 top-0 bg-[rgba(0,0,0,0.7)] pointer-events-none transition-opacity"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    show={inProp}
    style={{ zIndex: BASE_Z_INDEX - 1 }}
  />
);

export default Background;
