import {
  UserProfileDispensaryReview,
  UserProfileProductReview,
  UserProfileStrainReview,
} from "custom-types/Reviews";

import { Dispensary } from "./Dispensary";
import { Strain } from "./Strain";
import { Preferences } from "./StrainPreferences";

export type UserResponse = {
  avatar: string;
  dateOfBirth: string | null;
  email: string;
  id: number;
  isEmailVerified: boolean;
  name: string | null;
  phoneNumber: string | null;
  preferredName: string | null;
  publicId: string;
  smsOptedInAt: string | null;
  subscribeSmsNotifications: boolean;
  subscribeSmsNotificationsTermsVersion: number | null;
  username: string;
};

export type User = {
  dateOfBirth?: string;
  email?: string;
  id?: number;
  isEmailVerified?: boolean;
  name?: string;
  phoneNumber?: string;
  preferredName?: string;
  publicId?: string;
  username?: string;
};

export type FollowedDispensaries = {
  dispensaries?: Dispensary[];
  totalCount?: number;
  loading?: boolean;
};

export type FavoritedStrains = {
  strains?: Strain[];
  totalCount?: number;
  loading?: boolean;
};

export type StrainPreferences = {
  preferences?: Preferences[];
  strainMatchQuery?: string;
  strainSlug?: string;
  loading?: boolean;
};

export type UserReviewItems = (
  | UserProfileProductReview
  | UserProfileStrainReview
  | UserProfileDispensaryReview
)[];

export type UserReviews = {
  items?: UserReviewItems;
  totalCount?: number;
  loading: boolean;
};

export type UserReviewsApiResponse = {
  data: UserReviewItems;
  metadata: {
    totalCount: number;
  };
};

export enum FulfillmentPreference {
  DELIVERY = "delivery",
  PICKUP = "pickup",
}

export type MedCard = {
  maskedNumber: number;
  expiration: string;
  state: string;
};

export type CardImageData = {
  loading: boolean;
  data?: {
    createdAt: string | null;
    key?: string | null;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  error?: any;
};

export type CardImageDataResponse = {
  id: number;
  userId: number;
  medicalIdS3Key: string | null;
  stateIdS3Key: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  medicalIdUpdatedAt: string | null;
  stateIdUpdatedAt: string | null;
};

export type UserState = User & {
  fulfillmentPreference?: FulfillmentPreference;
  prefersMedical?: boolean;
  favoritedStrains?: FavoritedStrains;
  strainPreferences?: StrainPreferences;
  followedDispensaries?: FollowedDispensaries;
  reviews?: UserReviews;
  loading: boolean;
  isLoggedOutRedirect?: boolean;
  medCard?: MedCard;
  governmentCardImageData?: CardImageData;
  medicalCardImageData?: CardImageData;
};

export type NotificationSettingsInterest = {
  subject: string;
  title: string;
  description: string;
  enabled: boolean;
};

export type NotificationSettings = {
  interests: NotificationSettingsInterest[];
};

export type UserNotification = {
  id: string;
  title: string;
  content: string;
  imageUrl: string;
  readAt: string;
  createdAt: string;
  url: string;
};
