"use client";

import React, { HTMLAttributes } from "react";
import { useDispatch } from "react-redux";

import { useEventTracker } from "hooks/useEventTracker";
import { OPEN_NAVIGATION } from "redux/reducers/header";

import HamburgerMenuIcon from "components/Icons/hamburger_menu.svg";

export const HamburgerButton = React.forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement>
>(({ children }, ref) => {
  const dispatch = useDispatch();
  const { publishEvent } = useEventTracker();

  return (
    <div className="flex flex-shrink-0 relative mr-lg header__menu">
      <button
        data-testid="Menu"
        aria-label="Open site navigation"
        onClick={() => {
          publishEvent({
            action: "click",
            category: "header navigation",
            label: "hamburger - open",
          });
          dispatch({ type: OPEN_NAVIGATION });
        }}
        ref={ref}
      >
        <HamburgerMenuIcon height="24" width="24" />
        {children}
      </button>
    </div>
  );
});
