import capitalize from "lodash/capitalize";
import map from "lodash/map";

import {
  CurrentStatus,
  ScheduleType,
  WeekDay,
  WeeklySchedule,
} from "custom-types/DispensarySchedules";

// today's weekday name
export const getTodayWeekday = (): WeekDay =>
  new Date()
    .toLocaleDateString("en-US", {
      weekday: "long",
    })
    .toLowerCase() as WeekDay;

export const getTomorrowWeekday = (): WeekDay => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  return now
    .toLocaleDateString("en-US", {
      weekday: "long",
    })
    .toLowerCase() as WeekDay;
};

const timeZoneAbbr: { [key: string]: string } = {
  ADT: "AT",
  AKDT: "AKT",
  AKST: "AKT",
  AST: "AT",
  CDT: "CT",
  CST: "CT",
  EDT: "ET",
  EST: "ET",
  HDT: "HT",
  HST: "HT",
  MDT: "MT",
  MST: "MT",
  PDT: "PT",
  PST: "PT",
};

export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getShortenedTimeZone = (storeTimeZone: string) => {
  if (storeTimeZone === userTimeZone) {
    return null;
  }

  if (timeZoneAbbr[storeTimeZone]) {
    return timeZoneAbbr[storeTimeZone];
  }

  const timeZoneWithDate = new Intl.DateTimeFormat("en-US", {
    timeZone: storeTimeZone,
    timeZoneName: "short",
  }).format();

  const timeZoneWithDaylightOrStandard = timeZoneWithDate.split(" ")[1];

  return timeZoneAbbr[timeZoneWithDaylightOrStandard];
};

// formats military time to standard time and adds AM/PM
export const formatTime = (time?: string) => {
  if (!time) {
    return null;
  }

  // get individual time parts and convert them to integers
  const [hour, minute] = time.split(":").map(Number);
  const hourString = hour === 0 ? 12 : hour;
  const minuteString = minute === 0 ? "" : `:${minute}`;
  const isPm = hour >= 12;

  if (isPm && hour === 12) {
    return `${hourString}${minuteString}pm`;
  }

  return isPm
    ? `${hourString - 12}${minuteString}pm`
    : `${hourString}${minuteString}am`;
};

const getClosedMessage = (
  dayDisplay: string,
  time: string | null | undefined,
  shortenedTimeZone: string | null,
) => {
  return time
    ? `Closed until ${dayDisplay} ${formatTime(time)} ${
        shortenedTimeZone ? shortenedTimeZone : ""
      }`
    : null;
};

const getOpenMessage = (
  dayDisplay: string | null,
  time: string | null | undefined,
  isStore: boolean,
  shortenedTimeZone: string | null,
) =>
  time
    ? `${isStore ? `Open` : `available`} until ${dayDisplay}${formatTime(
        time,
      )} ${shortenedTimeZone ? shortenedTimeZone : ""}`
    : null;

// returns a formatted status message for store, pickup, or delivery schedules
export const getStatusMessage = (
  hasPreorder: boolean,
  status: CurrentStatus | null | undefined,
  storeTimeZone: string,
  type: "store" | "pickup" | "delivery",
) => {
  const { isOpen, statusChangeAt } = status ?? {};

  if (isOpen === null || statusChangeAt === null) {
    return null;
  }

  // returns "tomorrow" or day of the week for the message
  const statusChangeDay = `${
    statusChangeAt?.day === getTomorrowWeekday()
      ? "tomorrow"
      : capitalize(statusChangeAt?.day)
  }`;

  const shortenedTimeZone = getShortenedTimeZone(storeTimeZone);

  // schedule is open
  if (isOpen) {
    return getOpenMessage(
      statusChangeAt?.day !== getTodayWeekday() ? `${statusChangeDay} at ` : "",
      statusChangeAt?.time,
      type === "store",
      shortenedTimeZone,
    );
  }

  // schedule type is pickup or store, schedule is closed and preorder is available
  if (type !== "delivery" && !isOpen && hasPreorder) {
    return `Preorder until ${
      statusChangeDay.toLowerCase() === getTodayWeekday()
        ? ``
        : `${statusChangeDay} at `
    }${formatTime(statusChangeAt?.time)} ${
      shortenedTimeZone ? shortenedTimeZone : ""
    }`;
  }

  // schedule is closed
  return getClosedMessage(
    statusChangeAt?.day !== getTodayWeekday() ? `${statusChangeDay} at ` : "",
    statusChangeAt?.time,
    shortenedTimeZone,
  );
};

// returns an array of days with open/close times for a given schedule type
export const formatSchedule = (
  unformattedSchedule: WeeklySchedule,
  scheduleType: ScheduleType,
) => {
  return map(unformattedSchedule, (schedule, day) => {
    if (!schedule[scheduleType]) {
      return { day };
    }

    const { open, close } = schedule[scheduleType] || {};

    const openTime = open && formatTime(open.time);
    const closeTime = close && formatTime(close.time);
    return {
      close: { ...close, time: closeTime },
      day,
      open: { ...open, time: openTime },
    };
  });
};
