"use client";

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import addEmailSignup from "api/requests/addEmailSignup";
import getFooterContent from "api/requests/directus/getFooterContent";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { getLocationState } from "redux/selectors/location";

import { FooterLink } from "./FooterLink";
import { useFooterClickEventTracker } from "./useFooterClickEventTracker";

const DEFAULT_HEADER = "Stay In Touch";
const DEFAULT_SUBCOPY =
  "Receive updates on new products, special offers, and industry news.";

export const NewsletterSignup = () => {
  const domainCountryCode = useDomainCountryCode();
  const locationState = useSelector(getLocationState);

  const [copy, setCopy] = useState<{
    header: string;
    subCopyCa?: string | null;
    subCopyUs: string;
  }>({ header: DEFAULT_HEADER, subCopyUs: DEFAULT_SUBCOPY });
  const [email, setEmail] = useState("");
  const [signupCompleted, setSignupCompleted] = useState(false);
  const [signupError, setSignupError] = useState(false);

  const { createPublishFooterClickEventFunction } =
    useFooterClickEventTracker();

  useEffect(() => {
    getFooterContent().then((content) => {
      if (
        content?.email_signup_section_header &&
        content?.email_signup_section_sub_copy
      ) {
        setCopy({
          header: content.email_signup_section_header,
          subCopyCa: content.email_signup_section_ca_sub_copy,
          subCopyUs: content.email_signup_section_sub_copy,
        });
      }
    });
  }, []);

  const signup = async (e: React.FormEvent) => {
    e.preventDefault();

    createPublishFooterClickEventFunction("email sign up")();
    setSignupError(false);

    const successful = await addEmailSignup(
      email,
      locationState,
      "footer-subscribe",
    );

    setSignupError(!successful);
    setSignupCompleted(successful);
  };

  return (
    <section className="border-b border-light-grey flex-1 pt-xl">
      <div className="flex font-extrabold items-center justify-between pb-sm text-xs uppercase w-full">
        {copy.header}
      </div>
      <div>
        <div style={{ maxWidth: "488px" }}>
          <p className="mb-lg" data-testid="newsletter-signup-subcopy">
            {domainCountryCode === "CA" && copy.subCopyCa
              ? copy.subCopyCa
              : copy.subCopyUs}
          </p>
          <form className="fs-block" onSubmit={signup}>
            {signupCompleted ? (
              <p data-testid="newsletter-signup-success">
                Thanks for signing up!
              </p>
            ) : (
              <>
                <label className="font-bold text-xs" htmlFor="email-input">
                  Email address
                </label>
                <div className="flex border border-light-grey rounded">
                  <div className="form__field">
                    <input
                      data-testid="newsletter-signup-input"
                      className="bg-white text-sm py-sm"
                      id="email-input"
                      type="email"
                      name="email"
                      placeholder="email@address.com"
                      required
                      aria-label="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    className="bg-white px-sm text-green text-sm font-bold whitespace-nowrap underline"
                    data-testid="newsletter-signup-button"
                  >
                    sign up
                  </button>
                </div>
                <p
                  className={classNames(
                    "mt-sm text-error text-xs",
                    signupError ? "" : "hidden",
                  )}
                  data-testid="newsletter-signup-error"
                >
                  Something went wrong, please try again.
                </p>
              </>
            )}
          </form>
          <p className="mt-sm text-grey text-xs">
            By providing us with your email address, you agree to Leafly’s&nbsp;
            <FooterLink
              href="/info/terms-of-use"
              trackingLabel="terms of service - email"
            >
              Terms of Service
            </FooterLink>
            &nbsp;and&nbsp;
            <FooterLink
              href="/info/privacy-policy"
              trackingLabel="privacy policy - email"
            >
              Privacy Policy.
            </FooterLink>
          </p>
        </div>
      </div>
    </section>
  );
};
