import { type AxiosError } from "axios";

const formatAndReturnRejectedError = (
  error: AxiosError,
  message?: string,
  statusCode?: string | number,
): Promise<{ message: string; statusCode: string | number }> =>
  Promise.reject({
    message:
      message ||
      (error.response?.statusText !== "" && error.response?.statusText) ||
      error.message,
    statusCode: statusCode || error.response?.status || error.code || "UNKNOWN",
  });

export default formatAndReturnRejectedError;
