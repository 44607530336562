import React from "react";

import { FooterLink } from "./FooterLink";
import { ResponsiveAccordion } from "./ResponsiveAccordion";

export const AboutLeafly: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  return (
    <ResponsiveAccordion
      label="About Leafly"
      defaultExpanded={false}
      isResponsive={isResponsive}
    >
      <ul className="text-sm text-green font-bold">
        <li className="py-sm">
          <FooterLink href="/info/about" trackingLabel="about us">
            About us
          </FooterLink>
        </li>
        <li className="py-sm">
          <FooterLink href="/info/jobs" trackingLabel="careers">
            Careers
          </FooterLink>
        </li>
        <li className="py-sm">
          <FooterLink
            href="https://www.leafly.com/newsroom"
            trackingLabel="newsroom"
          >
            Newsroom
          </FooterLink>
        </li>
        <li className="py-sm">
          <FooterLink
            href="https://investor.leafly.com"
            trackingLabel="investor relations"
          >
            Investor relations
          </FooterLink>
        </li>
        <li className="py-sm">
          <FooterLink href="/info/contact" trackingLabel="contact us">
            Contact us
          </FooterLink>
        </li>
        <li className="py-sm">
          <FooterLink href="https://help.leafly.com" trackingLabel="faqs">
            FAQs
          </FooterLink>
        </li>
        <li className="py-sm mr-md">
          <FooterLink href="/info/accessibility" trackingLabel="accessibility">
            Accessibility
          </FooterLink>
        </li>
      </ul>
    </ResponsiveAccordion>
  );
};
