import React from "react";

export const Copyright = () => (
  <div className="my-xl text-xs flex flex-col items-center text-center">
    <div>&copy; {new Date().getFullYear()} Leafly, LLC</div>
    <div>
      Leafly and the Leafly logo are registered trademarks of Leafly, LLC. All
      Rights Reserved.
    </div>
  </div>
);
