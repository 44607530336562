import { CartContextDeliveryAddress } from "custom-types/Cart";
import { Location } from "custom-types/Location";

export const getIsPickup = (
  openForPickupOrPreorder: boolean,
  openForDelivery: boolean,
  userFulfillmentPreference: string | undefined,
) => {
  let deliveryOrPickup: string | undefined = "delivery";

  if (openForPickupOrPreorder && openForDelivery) {
    deliveryOrPickup = userFulfillmentPreference;
  }

  if (!openForDelivery) {
    deliveryOrPickup = "pickup";
  }

  if (!openForPickupOrPreorder) {
    deliveryOrPickup = "delivery";
  }

  const forDelivery = deliveryOrPickup ? deliveryOrPickup === "delivery" : true;

  return !forDelivery;
};

// This utility function takes in an address of the Location type and
// normalizes it into CartContextDeliveryAddress type in custom-types/Cart.
export const normalizeCartContextAddress = (
  deliveryAddress: Location,
): CartContextDeliveryAddress => {
  return {
    address1:
      `${deliveryAddress.street?.number} ${deliveryAddress.street?.name}` ||
      null,
    address2: deliveryAddress.addressLine2 || null,
    address_type: "residential",
    city: deliveryAddress.city || null,
    coordinates: null,
    country_code:
      deliveryAddress.countryCode || deliveryAddress.country || null,
    county: deliveryAddress.county || null,
    delivery_instructions: null,
    postal_code: deliveryAddress.postalCode || null,
    state: deliveryAddress.state_code || deliveryAddress.state || null,
  };
};

export const compareCartContextAddresses = (
  addressA: Location,
  addressB: Location,
) => {
  const normalAddressA = normalizeCartContextAddress(addressA);
  const normalAddressB = normalizeCartContextAddress(addressB);

  return JSON.stringify(normalAddressA) === JSON.stringify(normalAddressB);
};
