import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import mapValues from "lodash/mapValues";
import omitBy from "lodash/omitBy";
import { createWrapper } from "next-redux-wrapper";

import { AppStore, makeStore } from "./store";

export const wrapper = createWrapper<AppStore>(
  makeStore,
  /**
   * Checking for the presence of window here allows us to avoid shipping a small
   * amount of code to the client; state is only serialized on the server.
   */
  typeof window === "undefined"
    ? {
        /**
         * Serializing redux state from the server in `getServerSideProps` fails when
         * `undefined` values are encountered, as Next.js uses JSON to serialize props,
         * and JSON does not have a representation of `undefined`. To work around this,
         * we'll remove any undefined values during the serialization step provided by
         * `next-redux-wrapper`. The only drawback to this approach is that we won't
         * be able to rely on the `in` operator to detect if a certain key that is
         * potentially undefined is present in our state tree. In practice, this edge
         * case should be rare enough that this is safe.
         */
        serializeState: (state) => removeUndefinedValues(state),
      }
    : {},
);

type RemoveUndefined<T> = {
  [K in keyof T]: T[K] extends undefined
    ? never
    : T[K] extends Record<string, unknown>
      ? RemoveUndefined<T[K]>
      : T[K];
};

function removeUndefinedValues<T extends object>(state: T): RemoveUndefined<T> {
  const cleanState = omitBy(state, isUndefined);

  return mapValues<T>(cleanState, (value: unknown) => {
    return isObject(value) && !isArray(value)
      ? removeUndefinedValues(value)
      : value;
  }) as RemoveUndefined<T>;
}
