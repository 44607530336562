import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";

const DEFAULT_WAIT = 300;
const MOBILE_BREAKPOINT = 768;

/**
 * Caution!
 *
 * This hook has a high likelihood of introducing hydration mismatch errors.
 * When called during server-side rendering, it's impossible to know what the
 * eventual window size will be when the page is hydrated on the client, at
 * which point the actual window dimensions will mutate the server-rendered
 * page. React _really_ doesn't like this, and Next.js will complain about it,
 * loudly. There's probably a way of doing what you want using CSS. If there
 * really isn't, then consider ensuring that this hook is only called client-
 * side, and avoid server rendering entirely to ensure there is no mismatch.
 */
export default function useWindowSize({ wait = DEFAULT_WAIT } = {}) {
  const [windowSize, setWindowSize] = useState({
    height: typeof window !== "undefined" ? window.innerHeight : undefined,
    isMobile:
      typeof window !== "undefined"
        ? window.innerWidth < MOBILE_BREAKPOINT
        : undefined,
    width: typeof window !== "undefined" ? window.innerWidth : undefined,
  });

  const handleResize = useCallback(
    debounce(() => {
      if (
        window.innerWidth !== windowSize.width ||
        window.innerHeight !== windowSize.height
      ) {
        setWindowSize({
          height: window.innerHeight,
          isMobile: window.innerWidth < MOBILE_BREAKPOINT,
          width: window.innerWidth,
        });
      }
    }, wait),
    [wait],
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      handleResize.cancel();
    };
  }, [handleResize]);

  return windowSize;
}
