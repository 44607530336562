"use client";

import React, { PropsWithChildren } from "react";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";

import { useBreakpointHelper } from "hooks/useBreakpointHelper";

import ChevronIcon from "components/Icons/ChevronIcon";

export const ResponsiveAccordion: React.FC<
  PropsWithChildren<{
    defaultExpanded: boolean;
    isResponsive?: boolean;
    label: string;
  }>
> = ({ children, defaultExpanded, isResponsive = true, label }) => {
  const isLargeBreakpoint = !useBreakpointHelper();

  return (
    <Disclosure defaultOpen={defaultExpanded}>
      <Disclosure.Button
        className={classNames(
          "group font-extrabold text-xs uppercase py-lg w-full  flex items-center justify-between",
          {
            "lg:cursor-default": isResponsive,
          },
        )}
        as={isResponsive && isLargeBreakpoint ? "div" : undefined}
        /**
         * Even if this element is rendered as a div on >= large breakpoints
         * to disable the accordion functionality, we also have to mark is as
         * disabled in order to avoid screen readers from announcing it as an
         * interactive element.
         */
        disabled={isResponsive && isLargeBreakpoint}
      >
        {label}

        <ChevronIcon
          aria-hidden
          className={classNames(
            "group-data-[headlessui-state=open]:rotate-180 pointer-events-none transition-transform",
            {
              "lg:hidden": isResponsive,
            },
          )}
        />
      </Disclosure.Button>
      <Disclosure.Panel static={isResponsive && isLargeBreakpoint}>
        {children}
      </Disclosure.Panel>
    </Disclosure>
  );
};
