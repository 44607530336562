import React, { memo, useCallback, useState } from "react";
import cx from "classnames";
import dynamic from "next/dynamic";

import ImageFallback from "components/ImageFallback";

import ImageElement from "./ImageElement";
import PictureSourceTags from "./PictureSourceTags";

const PreloadLinks = dynamic(() => import("./PreloadLinks"));

export type Size = number | null;

export type Sizes = [number] | [Size, Size, Size, Size, Size, number];

export type ImageProps = {
  /** Required. Describes the image, can be left
   * as empty string for presentation elements
   * */
  alt: string;
  /** Optional. Turns image into a background. Positioning
   * and fit can be changed with backgroundFit and backgroundPosition
   * properties
   * @defaultValue `false`
   * */
  background?: boolean;
  /** Optional. Adds object-fit css property to image to scale image.
   * @defaultValue `cover`
   * */
  backgroundFit?: "contain" | "cover" | "fill" | "scale-down";
  /** Optional. Default is center. Adds object-position css property to image to
   * position image within its container.
   * @defaultValue `center` */
  backgroundPosition?: string;
  /** Optional. Classes for the image container, not the image itself. */
  className?: string;
  /** Optional. Default is false. Disables lazy loading, used for images
   * that you know will be above the fold
   * @defaultValue `false`
   */
  disableLazyLoad?: boolean;
  /** Optional. Classes for the image itself. */
  imageClass?: string;
  /** Optional. Object containing imgix parameters use to modify the image. */
  imgixParams?: Record<string, string>;
  /** Optional. The mobile specific image. */
  mobileSrc?: string;
  /** Optional. If true, image urls will be preloaded. Useful for optimal perfomance of above the fold images. */
  preload?: boolean;
  /** Optional. If defined, will resposively fit a square or rectangle container. */
  ratio?: "rectangle" | "square";
  /** Required. Each position in this array is assigned to a min-width
   * screen size starting at 320 and continuing with 544, 768, 1025
   * and 1440. If there is a screen size you don't want to cover,
   * a null value is accepted. The last value is the default and
   * that must be a number. For example sizes={[100, 400, null, 300, null, 400]}.
   * If the image stays the same width at all break points, then you
   * can use an array with a single number in it. For example sizes={[300]}. */
  sizes: Sizes;
  /** Required. The main source url for the image */
  src: string;
  /** Optional. The tablet specific image. */
  tabletSrc?: string;
  /** Optional. Custom fallback image. */
  customImageFallback?: string;
};

const Image = ({
  alt,
  background = false,
  backgroundFit = "cover",
  backgroundPosition = "center",
  className = "",
  disableLazyLoad = false,
  imageClass = "",
  imgixParams,
  mobileSrc,
  preload,
  ratio,
  sizes,
  src,
  tabletSrc = "",
  customImageFallback,
}: ImageProps) => {
  const [error, setError] = useState<boolean>(false);

  const sharedProps = {
    disableLazyLoad,
    params: imgixParams,
    sizes,
  };

  const onError = useCallback(() => setError(true), []);

  const baseSrc = mobileSrc || src;
  const imageProps = {
    alt,
    background,
    backgroundFit,
    backgroundPosition,
    baseSrc,
    imageClass,
    ratio,
    setError: onError,
  };

  return (
    <>
      {preload && (
        <PreloadLinks
          mobileSrc={mobileSrc}
          params={imgixParams}
          sizes={sizes}
          src={src}
          tabletSrc={tabletSrc}
        />
      )}
      <div
        className={cx(
          "image-container",
          {
            "apply-ratio image--16:9":
              !background && ratio === "rectangle" && !error,
            "apply-ratio image--1:1":
              !background && ratio === "square" && !error,
            "background absolute inset-0 h-full w-full z-0":
              background && !error,
            block: !error,
            "image-error": error,
            "relative overflow-hidden": !background,
          },
          className,
        )}
        data-testid="image-container"
      >
        {sizes.length === 1 ? (
          <ImageElement
            data-testid="image-single-image"
            {...imageProps}
            {...sharedProps}
          />
        ) : (
          <picture data-testid="image-picture-element">
            <PictureSourceTags
              mobileSrc={mobileSrc}
              tabletSrc={tabletSrc}
              src={src}
              {...sharedProps}
            />
            <ImageElement
              data-testid="image-picture-image"
              {...imageProps}
              {...sharedProps}
            />
          </picture>
        )}
        {error && !customImageFallback && (
          <div
            className="error bg-white absolute h-full w-full top-0 justify-center items-center whitespace-normal z-10"
            data-testid="image-error-container"
          >
            <span className="leading-snug text-xs text-center">
              Image Not Found
            </span>
          </div>
        )}
        {error && customImageFallback && (
          <ImageFallback
            {...imageProps}
            {...sharedProps}
            customImageFallback={customImageFallback}
          />
        )}
      </div>
    </>
  );
};

export default memo(Image);
