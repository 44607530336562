import generateImgixUrl from "./generateImgixUrl";
import isImageSrcPreSigned from "./isImageSrcPreSigned";

export default (
  url: string,
  size: number,
  disableLazyLoad = false,
  params?: Record<string, string>,
  isPictureElement = false,
) => {
  if (isImageSrcPreSigned(url)) {
    const attributeName = isPictureElement
      ? disableLazyLoad
        ? "srcSet"
        : "data-srcset"
      : disableLazyLoad
        ? "src"
        : "data-src";
    return {
      [attributeName]: url,
    };
  }

  const imageUrl = generateImgixUrl(url, {
    ...params,
    w: `${size}`,
  });

  return {
    [disableLazyLoad ? "srcSet" : "data-srcset"]:
      `${imageUrl}&dpr=1 1x, ${imageUrl}&dpr=2 2x`,
  };
};
