import { Action, Category } from "constants/events";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { localizeText } from "utils/localizeText";

import NavigationItem from "./NavigationItems/NavigationItem";
import SidebarWrapper from "./SidebarWrapper/SidebarWrapper";

const SidebarNavigation: React.FC<{
  returnFocusRef: React.RefObject<HTMLElement>;
}> = ({ returnFocusRef }) => {
  const countryCode = useDomainCountryCode();
  const { publishEvent } = useEventTracker();
  const isCanada = countryCode === "CA";
  const dispensaryText = localizeText("Dispensaries", countryCode);

  const trackLinkClick = (text: string) =>
    publishEvent({
      action: Action.click,
      category: Category.headerNavigation,
      label: text.toLowerCase(),
    });

  return (
    <SidebarWrapper returnFocusRef={returnFocusRef}>
      <ul
        aria-label="Strains navigation"
        className="flex flex-col gap-xl pt-xl"
      >
        <NavigationItem
          href="/strains/lists"
          highlight={["/start-exploring", "/explore", "/strains"]}
          onClick={() => trackLinkClick("Strains")}
        >
          Strains
        </NavigationItem>
      </ul>
      <ul
        aria-label="Shopping-related navigation"
        className="flex flex-col gap-xl pt-xl"
      >
        <li className="text-xs font-extrabold uppercase text-default">Shop</li>

        <NavigationItem
          href="/shop"
          highlight={["/shop"]}
          onClick={() => trackLinkClick("Shop")}
        >
          Shop
        </NavigationItem>

        <NavigationItem
          href="/delivery"
          highlight={["/delivery"]}
          onClick={() => trackLinkClick("Delivery")}
        >
          Delivery
        </NavigationItem>

        <NavigationItem
          href="/deals"
          highlight={["/deals"]}
          onClick={() => trackLinkClick("Deals")}
        >
          Deals
        </NavigationItem>

        <NavigationItem
          href="/dispensaries"
          highlight={["/dispensaries", "/dispensary-info", "/cannabis-store"]}
          onClick={() => trackLinkClick(dispensaryText)}
        >
          {dispensaryText}
        </NavigationItem>

        {!isCanada && (
          <NavigationItem
            href="/cbd-stores"
            highlight={["/cbd-stores"]}
            onClick={() => trackLinkClick("CBD Stores")}
          >
            CBD Stores
          </NavigationItem>
        )}

        <NavigationItem
          href="/brands"
          highlight={["/brands"]}
          onClick={() => trackLinkClick("Brands")}
        >
          Brands
        </NavigationItem>

        <NavigationItem
          href="/products"
          highlight={["/products"]}
          onClick={() => trackLinkClick("Products")}
        >
          Products
        </NavigationItem>
      </ul>

      <ul
        aria-label="Learn-related navigation"
        className="flex flex-col gap-xl pt-xl"
      >
        <li className="text-xs font-extrabold uppercase text-default">Learn</li>

        <NavigationItem
          href="/news/cannabis-101"
          highlight={["/news/cannabis-101"]}
          onClick={() => trackLinkClick("Cannabis 101")}
        >
          Cannabis 101
        </NavigationItem>

        <NavigationItem
          href={isCanada ? "/news/canada" : "/news"}
          highlight={[/\/news(?!\/(cannabis-101|medicalcannabis))/g]}
          onClick={() => trackLinkClick("News")}
        >
          News
        </NavigationItem>

        <NavigationItem href="/learn" onClick={() => trackLinkClick("Learn")}>
          Leafly Learn
        </NavigationItem>

        <NavigationItem
          href="/learn/beginners-guide-to-cannabis"
          highlight={["/learn/beginners-guide-to-cannabis"]}
          onClick={() => trackLinkClick("science of cannabis")}
        >
          Science of cannabis
        </NavigationItem>
      </ul>
      <ul
        aria-label="Strains navigation"
        className="flex flex-col gap-xl pt-xl"
      >
        <NavigationItem
          href="/medical-marijuana-doctors"
          highlight={["/medical-marijuana-doctors"]}
          onClick={() => trackLinkClick("Doctors")}
        >
          Doctors
        </NavigationItem>
        <NavigationItem
          href="/social-impact"
          onClick={() => trackLinkClick("Social impact")}
        >
          Social impact
        </NavigationItem>
        <NavigationItem
          as="a"
          href="https://success.leafly.com/labs"
          onClick={() => trackLinkClick("Lab partners")}
        >
          Lab partners
        </NavigationItem>
      </ul>
    </SidebarWrapper>
  );
};

export default SidebarNavigation;
