import React from "react";

import { Modal, ModalProps } from "./Modal";

type InformationalModalProps = {
  expository?: boolean;
} & Omit<ModalProps, "width">;

/**
 * InformationalModal renders a Modal that contains actions that suggest a task
 * to complete. On mobile-sized devices, this modal takes up the entire screen.
 *
 * For general modal usage, see the sibling index module's documentation.
 */
export const InformationalModal: React.FC<InformationalModalProps> = ({
  expository = false,
  ...props
}) => {
  const width = expository ? 660 : 320;

  return <Modal {...props} width={width} />;
};
