import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "redux/reducers/rootReducer";
import isValidStreetAddress from "utils/isValidStreetAddress";

export const getLocationState = (state: RootState) => state.location;
export const getCoordinates = createSelector(
  [getLocationState],
  (location) => location?.coordinates,
);

// user location picker or user ip lookup, regardless of .ca/.com domain. See getDomainCountryCode
export const getCountryCode = createSelector(
  [getLocationState],
  (location) => location.countryCode,
);
export const getCity = createSelector(
  [getLocationState],
  (location) => location.city,
);
export const getGeoSlug = createSelector(
  [getLocationState],
  (location) => location.geoSlug,
);
export const getState = createSelector(
  [getLocationState],
  (location) => location.state,
);
export const getPostalCode = createSelector(
  [getLocationState],
  (location) => location.zip,
);
export const getFormattedLocation = createSelector(
  [getLocationState],
  (location) => location.formattedLocation,
);
export const getDeliveryAddress = createSelector(
  [getLocationState],
  (location) => location.deliveryAddress,
);

export const getIsValidDeliveryAddress = createSelector(
  [getLocationState],
  (location) =>
    !!location.deliveryAddress?.street?.number &&
    !!location.deliveryAddress?.street?.name &&
    !!location.deliveryAddress?.city &&
    !!location.deliveryAddress?.state &&
    !!location.deliveryAddress?.postalCode &&
    !!location.deliveryAddress?.countryCode,
);

export const getIsValidStreetAddress = createSelector(
  [getLocationState],
  (location) => isValidStreetAddress(location),
);

export const getFormattedAddress = createSelector(
  getLocationState,
  getIsValidStreetAddress,
  (location, isValidAddress) =>
    isValidAddress
      ? `${location.street?.number} ${location.street?.name}, ${location.city}, ${location.state}`
      : null,
);

export const getHasLoadedLocationCookie = createSelector(
  getLocationState,
  (location) => location.locationLoadedFromCookie,
);

export const getIndicatedAddress = createSelector(
  [getFormattedAddress, getFormattedLocation],
  (formattedAddress, formattedCityStateLocation) =>
    formattedAddress || formattedCityStateLocation,
);

export const getIsDefaultLocation = createSelector(
  getLocationState,
  (location) => location.defaultLocation,
);

export const getShouldOpenLocationModal = createSelector(
  getLocationState,
  (location) => location.requestingLocation,
);
