import axios from "axios";

import postalServiceApi from "api/services/postal-service";
import { LocationState } from "redux/reducers/location";
import { getCookie } from "utils/cookies";
import logError from "utils/logError";

const addEmailSignup = async (
  email: string,
  userLocation: LocationState,
  source: string,
) => {
  try {
    const { city, state, zip, countryCode } = userLocation;
    // e.g. leaflycom-footer-subscribe
    const domainSource =
      countryCode === "CA" ? `leaflyca-${source}` : `leaflycom-${source}`;

    await postalServiceApi.post(
      "/v1/signup",
      {
        countryCode,
        email: email,
        postal_city: city,
        postal_state: state,
        postal_zipcode: zip,
        source: domainSource,
        subscribe_newsletter: true,
        subscribe_newsletter_deals: true,
        subscribe_newsletter_dedicated: true,
      },
      { withCredentials: true },
    );

    const tuneTrackingId = getCookie("tuneTrackingId");
    if (tuneTrackingId) {
      await axios
        .get(
          `https://leaflypartners.go2cloud.org/aff_lsr?transaction_id=${tuneTrackingId}`,
        )
        .catch(() => false);
    }

    return true;
  } catch (e) {
    logError(e.message, {
      functionName: "addEmailSignup",
      service: "postalservice",
      statusCode: e.statusCode,
    });

    return false;
  }
};

export default addEmailSignup;
