import directus from "api/services/directus";
import { GlobalFooter } from "custom-types/Directus";
import logError from "utils/logError";

const getFooterContent = async () => {
  try {
    const result = await directus.get<GlobalFooter[]>("/global_footer", {
      params: {
        "filter[status][_eq]": "published",
        sort: "-created_on",
      },
    });

    const { data } = result || {};
    const [content] = data;

    return content;
  } catch (e) {
    logError(e.message, {
      functionName: "getFooterContent",
      service: "directus",
      statusCode: e.statusCode,
    });

    return null;
  }
};

export default getFooterContent;
