import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";

export type ReservationsApiErrors = { title: string; detail: string }[];

export type ReservationsApiErrorsResponse = {
  errors: ReservationsApiErrors;
};

export const reservationsApi = ({
  domainCountryCode = "US",
}: {
  domainCountryCode: string;
}) => {
  // Initialize the service request with the app and environment
  const reservationsApi = serviceRequest();
  reservationsApi.defaults.withCredentials = true;

  reservationsApi.defaults.baseURL =
    domainCountryCode === "CA"
      ? publicConfig.services.reservationsApiCa.url
      : publicConfig.services.reservationsApi.url;

  return reservationsApi;
};
