import React, { useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import classNames from "classnames";
import FocusLock from "react-focus-lock";

import Background from "components/botanic/Modal/internal/Background";
import { BASE_Z_INDEX } from "components/botanic/Modal/internal/ModalContext";

export const SidebarModal: React.FC<{
  returnFocusRef: React.RefObject<HTMLElement>;
  open: boolean;
  children?: React.ReactNode;
  onCloseClick?: () => unknown;
}> = ({ children, returnFocusRef, open, onCloseClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollLockTarget = containerRef.current;
    if (open) {
      /**
       * Record the container ref value here, as the reference to the element is
       * otherwise not available when the cleanup function of this useEffect is run.
       */
      scrollLockTarget && disableBodyScroll(scrollLockTarget);
    } else {
      scrollLockTarget && enableBodyScroll(scrollLockTarget);
    }

    return () => {
      scrollLockTarget && enableBodyScroll(scrollLockTarget);
    };
  }, [open]);

  return (
    <FocusLock
      disabled={!open}
      onDeactivation={() => {
        if (returnFocusRef && returnFocusRef.current) {
          setTimeout(() => {
            returnFocusRef.current?.focus();
          }, 1);
        }
      }}
    >
      <div
        className={classNames(
          "bg-white inset-y-0 left-0 fixed transition-transform motion-reduce:transition-none overflow-auto text-green z-modal",
          {
            "translate-x-0": open,
            "translate-x-[-100%]": !open,
          },
        )}
        style={{
          width: 320,
        }}
        ref={containerRef}
      >
        {children}
      </div>
      <Background inProp={open} />
      {open && (
        <div
          className="background-handler fixed bottom-0 left-0 right-0 top-0 cursor-default"
          style={{ zIndex: BASE_Z_INDEX - 1 }}
          onClick={onCloseClick}
          /**
           * This element only makes sense when viewing a website and using a
           * touch- or mouse-based interface. When using keyboard navigation
           * or a screen reader, the close button in the navigation is
           * sufficient without making this element interactive. Given that,
           * it's safe to add aria-hidden this element to avoid unnecessary
           * accessibility checks.
           */
          aria-hidden
        ></div>
      )}
    </FocusLock>
  );
};
