import axios from "axios";
import { getCookie, setCookie } from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";
import { IncomingMessage } from "http";
import pick from "lodash/pick";

import publicConfig from "config/public";
import { Location } from "custom-types/Location";

import { GeolocationResult } from "./getGeolocation";

export const FALLBACK_LOCATION: Location = {
  city: "Seattle",
  coordinates: { latitude: 47.6062095, longitude: -122.3320708 },
  country: "United States",
  countryCode: "US",
  country_code: "US",
  defaultLocation: true,
  formattedLocation: "Seattle, WA",
  formatted_location: "Seattle, WA",
  geoSlug: "seattle-wa-us",
  place_id: "ChIJVTPokywQkFQRmtVEaUZlJRA",
  slug: "seattle-wa-us",
  state: "Washington",
  state_code: "WA",
  street: { name: "", number: "" },
  sublocality: "",
  zip: "98164",
};

const {
  cookieDomain,
  cookieDomainCa,
  services: {
    geoIpApi: { url: geoIpApiUrl },
  },
} = publicConfig;

export const getUserLocationData = async (
  domainCountryCode: string,
  context?: OptionsType,
): Promise<Location> => {
  let location: Location | undefined = getUserLocationByCookie(context);

  if (!location) {
    try {
      location = await getUserLocationByIp(context);
      if (location) {
        setLocationCookie(
          { ...location, isUserLocation: true },
          domainCountryCode,
          context,
        );
      } else {
        throw new Error("failed to get user location");
      }
    } catch {
      location = FALLBACK_LOCATION;
    }
  }

  return {
    ...location,
    countryCode: location.country_code,
    formattedLocation: location.formatted_location,
    geoSlug: location.slug,
  };
};

const getUserLocationByCookie = (
  context?: OptionsType,
): Location | undefined => {
  const locationCookie = getCookie("leafly-location", context);

  try {
    return JSON.parse(String(locationCookie));
  } catch {
    return undefined;
  }
};

const getUserLocationByIp = async (
  context?: OptionsType,
): Promise<Location | undefined> => {
  let ip;

  if (context) {
    ip = getUserIp(context);

    if (ip?.slice(0, 7) === "::ffff:") {
      ip = ip.slice(7);
    }
  }

  try {
    const response = await axios.get<GeolocationResult>(
      `${geoIpApiUrl}/geo/v1/geoip${ip ? `/${ip}` : ""}`,
    );

    if (response?.status === 200) {
      return pick(response.data, [
        "city",
        "coordinates",
        "country_code",
        "country",
        "formatted_location",
        "place_id",
        "slug",
        "state_code",
        "state",
        "street",
        "sublocality",
        "zip",
      ]);
    }
  } catch {
    return;
  }
};

const getUserIp = (context?: OptionsType): string | undefined => {
  const xForwardedFor = (context?.req as IncomingMessage)?.headers?.[
    "x-forwarded-for"
  ];

  if (xForwardedFor) {
    return String(xForwardedFor).split(",").pop();
  }

  return (context?.req as IncomingMessage)?.socket?.remoteAddress;
};

const setLocationCookie = (
  location: Location,
  domainCountryCode: string,
  context?: OptionsType,
): void => {
  const domain = domainCountryCode === "CA" ? cookieDomainCa : cookieDomain;

  setCookie("leafly-location", JSON.stringify(location), {
    domain,
    maxAge: 86400 * 365 * 1000,
    path: "/",
    ...context,
  });
};
