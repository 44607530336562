import { ThunkAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

import getUser from "api/requests/getUser";
import publicConfig from "config/public";
import { RootState } from "redux/reducers/rootReducer";
import { USER_LOAD, USER_PREFERENCE_CHANGE } from "redux/reducers/user";
import { getDomainCountryCode } from "redux/selectors/config";
import { setCookie } from "utils/cookies";
import { menuTypeCookieName, validatedMenuType } from "utils/menuTypeUtils";

export const loadUser = async (
  domainCountryCode: string,
  dispatch: Dispatch,
) => {
  const user = await getUser(domainCountryCode);

  dispatch({
    type: USER_LOAD,
    userData: user,
  });
};

export interface UserPreferenceChangeAction {
  type: typeof USER_PREFERENCE_CHANGE;
  prefersMedical: boolean;
}

export const setUserMenuPreference =
  (
    value: string,
  ): ThunkAction<void, RootState, unknown, UserPreferenceChangeAction> =>
  (dispatch, getState) => {
    const reduxState = getState();
    const countryCode = getDomainCountryCode(reduxState);
    const validCookieValue = validatedMenuType(value);

    if (validCookieValue) {
      setCookie(
        menuTypeCookieName,
        validCookieValue,
        countryCode === "CA"
          ? publicConfig.cookieDomainCa
          : publicConfig.cookieDomain,
      );

      dispatch({
        prefersMedical: value.toLowerCase() === "med",
        type: USER_PREFERENCE_CHANGE,
      });
    }
  };
