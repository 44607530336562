import { useEventTracker } from "hooks/useEventTracker";

export const useFooterClickEventTracker = () => {
  const { publishEvent } = useEventTracker();

  const createPublishFooterClickEventFunction = (label: string) => () => {
    publishEvent({
      action: "click",
      category: "footer",
      label,
    });
  };

  return {
    createPublishFooterClickEventFunction,
  };
};
