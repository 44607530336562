"use client";

import dynamic from "next/dynamic";

import { useToast } from "hooks/useToast";

const Toast = dynamic(() => import("components/Toast"));

export function Toaster() {
  const { instances, dismiss } = useToast();

  if (!instances.length) {
    return null;
  }

  return (
    <div className="fixed top-3 w-full z-overlay">
      {instances.map((toast) => (
        <Toast
          className="mt-sm bg-white"
          key={toast.id}
          message={toast.message}
          onDismiss={() => dismiss(toast.id)}
          type={toast.type}
        />
      ))}
    </div>
  );
}
