"use client";

import React, {
  createContext,
  ReactNode,
  ReactPortal,
  useContext,
} from "react";
import ReactDOM from "react-dom";

import { MODAL_PORTAL_ID } from "./ModalPortal";

type ModalRenderContextProps = {
  renderModal: (children: ReactNode) => ReactPortal | null;
};

const ModalRenderContext = createContext<ModalRenderContextProps | null>(null);
ModalRenderContext.displayName = "ModalRenderContext";

const renderModal = (children: ReactNode) => {
  if (typeof document === "undefined") {
    return null;
  }

  const portal = document.getElementById(MODAL_PORTAL_ID);

  if (!portal) {
    return null;
  }

  return ReactDOM.createPortal(children, portal);
};

export function ModalRenderProvider({ children }: { children: ReactNode }) {
  return (
    <ModalRenderContext.Provider value={{ renderModal }}>
      {children}
    </ModalRenderContext.Provider>
  );
}

export const useModalRenderContext = () => useContext(ModalRenderContext);
