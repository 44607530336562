export default (
  index: number,
  src: string,
  mobileSrc?: string,
  tabletSrc?: string,
) =>
  index < 2 && mobileSrc
    ? mobileSrc
    : index === 2 && tabletSrc
      ? tabletSrc
      : src;
