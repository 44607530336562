"use client";

import React from "react";
import { useClientSideSplitTreatments } from "@leafly-com/split-next";
import dynamic from "next/dynamic";

import { getSplitKey } from "utils/split/getSplitKey";

const LeafbotButton = dynamic(() => import("./LeafbotButton"));

const Leafbot = () => {
  const split = useClientSideSplitTreatments(
    ["leafbotChat_flag"],
    getSplitKey(),
  );

  if (split.isLoading || split.treatments.leafbotChat_flag.treatment !== "on") {
    return null;
  }

  return <LeafbotButton />;
};

export default Leafbot;
