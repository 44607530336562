import React from "react";
import Script from "next/script";

/**
 * This component loads the GPT script, and adds some global configuration for
 * Google ads. This might be something that we can move into Google Tag Manager.
 */
export const GooglePublisherTagScript = () => (
  <>
    <Script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
    <Script id="google-tag-enable">{`
      window.googletag = window.googletag || { cmd: [] };
      (function() {
        window.googletag.cmd.push(function() {
          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
        });
      })();
    `}</Script>
  </>
);
