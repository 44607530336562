import { useEffect, useLayoutEffect as internalUseLayoutEffect } from "react";

/*
 * This should not be needed very often. Most of the time, you want to use `useEffect`.
 *
 * useEffect does not have a React warning for SSR, so when we do need useLayoutEffect, we can swap it out only on the frontend.
 *
 * See https://reactjs.org/link/uselayouteffect-ssr
 * Also see: https://stackoverflow.com/a/66580539
 */
export const useLayoutEffect =
  typeof window === "undefined" ? useEffect : internalUseLayoutEffect;
