"use client";

import React, { useEffect, useRef } from "react";
import { useClientSideSplitTreatments } from "@leafly-com/split-next";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useOnClickOutside } from "usehooks-ts";

import { Action, Category, Label } from "constants/events";
import { CartItem } from "custom-types/Cart";
import { useEventTracker } from "hooks/useEventTracker";
import {
  ADD_TO_CART_DISMISS,
  AddToCartDismissAction,
} from "redux/reducers/cart";
import {
  getCartIsInStore,
  getCartSessionId,
  getContinueShopping,
  getLastItemAddedToCart,
} from "redux/selectors/cart";
import { getSplitKey } from "utils/split/getSplitKey";

import CloseIcon from "components/Icons/x.svg";
import Image from "components/Image";
import TrackImpression from "components/TrackImpression";

/* eslint-disable sort-keys-fix/sort-keys-fix -- exception: order is important */
const GRAM_WEIGHT_CONVERSIONS: Record<string, string> = {
  "1.0": "1g",
  "3.5": "1/8 oz",
  "7.0": "1/4 oz",
  "14.0": "1/2 oz",
  "28.0": "1 oz",
};
/* eslint-enable sort-keys-fix/sort-keys-fix -- exception: order is important */

const normalizeGramsToOunces = ({ packageUnit, packageSize }: CartItem) =>
  (packageUnit === "g" && GRAM_WEIGHT_CONVERSIONS[packageSize]) ||
  `${packageSize} ${packageUnit}`;

const AddToBagPopOver = () => {
  const lastItemAddedInBag = useSelector(getLastItemAddedToCart);
  const continueShopping = useSelector(getContinueShopping);
  const cartIsInStore = useSelector(getCartIsInStore);
  const cartSessionId = useSelector(getCartSessionId);

  const { publishEvent } = useEventTracker();
  const dispatch = useDispatch();

  const shouldShowAddToBagPopover = !!lastItemAddedInBag;

  const pathname = usePathname();
  const isBagPage = pathname === "/bag";

  const { treatments, isLoading } = useClientSideSplitTreatments(
    "NL-1391_continue-shopping-button_flag",
    getSplitKey(),
  );

  const continueShoppingButtonFlagOn =
    !isLoading &&
    treatments["NL-1391_continue-shopping-button_flag"].treatment === "on";

  const continueShoppingButton =
    continueShopping && Object.entries(continueShopping).length > 0;

  const continueShoppingButtonOnClick = () => {
    publishEvent({
      action: Action.click,
      brandId: lastItemAddedInBag?.menuItemData?.brandId,
      category: continueShopping?.brandIsPaid
        ? Category.brandPaid
        : Category.brandInfo,
      dispensaryId: lastItemAddedInBag?.menuItemData?.dispensaryId,
      label: "continue shopping at store",
    });
  };

  const dismissPopover = () => {
    dispatch<AddToCartDismissAction>({
      type: ADD_TO_CART_DISMISS,
    });
  };

  useEffect(() => {
    if (shouldShowAddToBagPopover) {
      publishEvent({
        action: Action.impression,
        brandId: lastItemAddedInBag?.menuItemData?.brandId,
        cartId: cartSessionId,
        category: Category.addToBag,
        dispensaryId: lastItemAddedInBag?.menuItemData?.dispensaryId,
        label: cartIsInStore
          ? `${Label.confirmation} - shop every menu`
          : Label.confirmation,
        menuItemId: lastItemAddedInBag?.menuItemData?.id,
        productId: lastItemAddedInBag?.menuItemData?.productId,
        strainId: lastItemAddedInBag?.menuItemData?.strainId,
      });

      setTimeout(() => shouldShowAddToBagPopover && dismissPopover(), 10000);
    }
  }, [shouldShowAddToBagPopover]);

  const unit = lastItemAddedInBag && normalizeGramsToOunces(lastItemAddedInBag);

  const ref = useRef(null);
  const handleClickOutside = () =>
    shouldShowAddToBagPopover && dismissPopover();
  useOnClickOutside(ref, handleClickOutside);

  return shouldShowAddToBagPopover ? (
    <div
      className="absolute bg-white text-default rounded shadow-low w-[300px] top-[36px] -right-2"
      data-testid="add-to-bag-popover"
      ref={ref}
    >
      <div className="absolute bg-white border-l border-leafly-white border-t top-[-9px] h-4 right-[11px] w-4 rotate-45" />
      <div className="flex justify-between items-center">
        <span className="text-xs font-extrabold flex-shrink-0 pl-lg uppercase">
          {cartIsInStore ? "item added to in-store cart" : "item added to cart"}
        </span>
        <button
          id="added-to-bag-close"
          className="p-lg"
          aria-label="Close item added to cart popup"
          onClick={dismissPopover}
        >
          <CloseIcon height="22" width="22" />
        </button>
      </div>
      <div className="flex px-lg mb-lg">
        {lastItemAddedInBag.menuItemData.imageUrl ? (
          <div className="mr-sm" style={{ width: 56 }}>
            <Image
              src={lastItemAddedInBag.menuItemData.imageUrl}
              alt={lastItemAddedInBag.menuItemData.name}
              sizes={[56]}
            />
          </div>
        ) : (
          <></>
        )}
        <div data-testid="cart-item" role="status" aria-label="Added to cart">
          <div className="mb-xs">{lastItemAddedInBag.menuItemData.name}</div>
          <span className="text-grey text-xs block">{unit}</span>
        </div>
      </div>
      {!isBagPage && (
        <div>
          <div
            className={classNames("px-lg text-center", {
              "mb-lg":
                (continueShopping &&
                  Object.entries(continueShopping).length === 0) ||
                !continueShopping,
            })}
          >
            {/* TODO: Can be converted to next/link once /bag uses getServersideProps */}
            <TrackImpression
              trackingFunction={() => {
                continueShoppingButton &&
                  continueShoppingButtonFlagOn &&
                  publishEvent({
                    action: Action.impression,
                    category: continueShopping?.brandIsPaid
                      ? Category.brandPaid
                      : Category.brandInfo,
                    dispensaryId:
                      lastItemAddedInBag?.menuItemData?.dispensaryId,
                    label: "view cart and checkout",
                  });
              }}
            >
              <a
                className="button block"
                href="/bag"
                onClick={() => {
                  publishEvent({
                    action: Action.click,
                    category: Category.addToBag,
                    dispensaryId:
                      lastItemAddedInBag?.menuItemData?.dispensaryId,
                    label: cartIsInStore
                      ? `${Label.viewBag} - shop every menu`
                      : Label.viewBag,
                  });
                  continueShoppingButton &&
                    continueShoppingButtonFlagOn &&
                    publishEvent({
                      action: Action.click,
                      brandId: lastItemAddedInBag?.menuItemData?.brandId,
                      category: continueShopping?.brandIsPaid
                        ? Category.brandPaid
                        : Category.brandInfo,
                      label: "view cart and checkout",
                    });
                }}
                data-testid="add-to-bag-popover-view-cart-link"
              >
                view cart & checkout
              </a>
            </TrackImpression>
          </div>
          {continueShoppingButton &&
            continueShoppingButtonFlagOn &&
            !isLoading && (
              <div className="px-lg mb-xl text-center mt-md">
                <TrackImpression
                  trackingFunction={() => {
                    publishEvent({
                      action: Action.impression,
                      brandId: lastItemAddedInBag?.menuItemData?.brandId,
                      category: continueShopping?.brandIsPaid
                        ? Category.brandPaid
                        : Category.brandInfo,
                      dispensaryId:
                        lastItemAddedInBag?.menuItemData?.dispensaryId,
                      label: "continue shopping at store",
                    });
                  }}
                >
                  {/* TODO: Can be converted to next/link once /bag uses getServersideProps */}
                  <a
                    className="button button--secondary block"
                    href={continueShopping?.dispensaryPath}
                    onClick={continueShoppingButtonOnClick}
                    data-testid="add-to-bag-popover-continue-shopping-link"
                  >
                    continue shopping at this store
                  </a>
                </TrackImpression>
              </div>
            )}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default AddToBagPopOver;
