import { createSelector } from "@reduxjs/toolkit";

import { CountryCode } from "custom-types/CountryCode";
import { RootState } from "redux/reducers/rootReducer";

const getConfigState = (state: RootState) => state.config;

export const getDomainCountryCode = createSelector(
  [getConfigState],
  (state) => state.domainCountryCode as CountryCode,
);
