import { useEffect, useState } from "react";
import throttle from "lodash/throttle";

const checkIsMobile = (width: number | null) => {
  return !!(width && width < 1025);
};
const getWindowWidth = () => {
  if (typeof window === "undefined") {
    return null;
  }

  return window?.innerWidth;
};

export const useBreakpointHelper = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(checkIsMobile(getWindowWidth()));
    const calculateBreakpoint = throttle(() => {
      setIsMobile(checkIsMobile(getWindowWidth()));
    }, 200);
    window.addEventListener("resize", calculateBreakpoint);

    return () => window.removeEventListener("resize", calculateBreakpoint);
  }, []);

  return isMobile;
};
