import React from "react";

import IconSvg from "components/IconSvg";

type Props = {
  message: string;
  iconFilePath?: string;
};

const Snackbar = ({ message, iconFilePath = "info.svg" }: Props) => (
  <div
    data-testid="snack-bar"
    className="flex items-center p-sm text-white bg-default shadow-low rounded w-full"
  >
    <IconSvg
      aria-hidden="true"
      filePath={iconFilePath}
      width="30"
      height="30"
      className="flex-shrink-0 mr-xs"
    />
    {message}
  </div>
);

export default Snackbar;
