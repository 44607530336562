import React, { useId } from "react";

import { Modal, ModalProps } from "./Modal";

type DecisionPointModalProps = Omit<ModalProps, "width" | "mobileFullscreen">;

/**
 * DecisionPointModal renders a Modal that contains a single choice that should
 * be made. This could be a confirmation of an action to be taken, or a prompt
 * displayed after an action has already been taken.
 *
 * For general modal usage, see the sibling index module's documentation.
 */
export const DecisionPointModal: React.FC<DecisionPointModalProps> = ({
  children,
  title,
  ...props
}) => {
  /**
   * Creates a unique DOM ID to be used to label the modal with the provided
   * title, when supplied.
   */
  const titleId = useId();

  return (
    <Modal
      {...props}
      width={560}
      aria-labelledby={titleId}
      mobileFullscreen={false}
    >
      {(...args) => (
        <div className="flex flex-col items-center justify-center pb-xl text-center">
          <h1 id={titleId} className="font-extrabold text-md pt-lg">
            {title}
          </h1>
          {children instanceof Function ? children(...args) : children}
        </div>
      )}
    </Modal>
  );
};
