import { DeliveryZoneDecisionResponse } from "custom-types/Compliance";

import { AllActions } from "./rootReducer";

export type DispensaryState = {
  deliveryZone?: DeliveryZoneDecisionResponse | null;
  followed: boolean;
};

export const initialState: DispensaryState = {
  deliveryZone: null,
  followed: false,
};

export const SET_DELIVERY_ZONE = "dispensary/setDeliveryZone";
export const DISPENSARY_FOLLOW = "dispensary/follow";
export const DISPENSARY_UNFOLLOW = "dispensary/unfollow";

export type SetDeliveryZoneAction = {
  type: typeof SET_DELIVERY_ZONE;
  deliveryZone: DeliveryZoneDecisionResponse | null;
};

export type DispensaryFollowAction = {
  type: typeof DISPENSARY_FOLLOW;
};

export type DispensaryUnfollowAction = {
  type: typeof DISPENSARY_UNFOLLOW;
};

export type DispensaryActions =
  | SetDeliveryZoneAction
  | DispensaryFollowAction
  | DispensaryUnfollowAction;

const dispensaryReducer = (
  state = initialState,
  action: AllActions,
): DispensaryState => {
  switch (action.type) {
    case SET_DELIVERY_ZONE:
      return {
        ...state,
        deliveryZone: action.deliveryZone,
      };
    case DISPENSARY_FOLLOW:
      return {
        ...state,
        followed: true,
      };
    case DISPENSARY_UNFOLLOW:
      return {
        ...state,
        followed: false,
      };
    default:
      return state;
  }
};

export default dispensaryReducer;
