const currencyFormatter = (
  price: number | string | undefined | null,
  countryCode: string,
  formatOptions?: Omit<Intl.NumberFormatOptions, "currency" | "style">,
) => {
  if (!price) {
    return;
  }
  return price.toLocaleString(`en-${countryCode}`, {
    ...formatOptions,
    currency: countryCode === "US" ? "USD" : "CAD",
    style: "currency",
  });
};

export const bigDollarSmallCentsFormatter = (
  price: number | string | undefined | null,
  countryCode: string,
) => {
  if (!price) {
    return;
  }
  const result = currencyFormatter(price, countryCode);
  if (typeof result === "undefined") {
    return;
  }
  const formattedPrice = result.toString();
  const cents = formattedPrice.split(".").pop();
  const dollar = formattedPrice.slice(1, formattedPrice.indexOf("."));
  return [dollar, cents];
};

export const centsToDollars = (num: number | string) =>
  Number(typeof num === "number" ? num / 100 : num);

export function formatCurrency(
  price: number | string,
  countryCode: string,
): {
  formattedPrice: string;
  cents: string;
  dollars: string;
  symbol: string;
};
export function formatCurrency(
  price: null | undefined,
  countryCode: string,
): undefined;
export function formatCurrency(
  price: number | string | null | undefined,
  countryCode: string,
) {
  if (!price) {
    return;
  }

  const amount = currencyFormatter(price, countryCode);
  const [dollars, cents] =
    bigDollarSmallCentsFormatter(price, countryCode) || [];
  const symbol = amount?.slice(0, 1);

  return {
    cents,
    dollars,
    formattedPrice: amount,
    symbol,
  };
}

export default currencyFormatter;
