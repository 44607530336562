import { AxiosRequestConfig } from "axios";

import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import logError from "utils/logError";

type SearchAutocompleteSuggestion = {
  distanceMi?: number;
  id: string;
  link: string;
  title: string;
  type: string;
};

const getSearchSuggestions = async (
  coordinates: Coordinates,
  countryCode = "US",
  searchText: string,
): Promise<Array<SearchAutocompleteSuggestion>> => {
  try {
    const { suggestions } = await consumerApi
      .get<{ suggestions: SearchAutocompleteSuggestion[] }>(
        "/api/search/v2/autocomplete",
        {
          "axios-retry": {
            retries: 0,
          },
          headers: {
            "x-country-code": countryCode,
          },
          params: {
            countryCode: countryCode,
            lat: coordinates.lat,
            lon: coordinates.lon,
            q: searchText,
          },
          timeout: 30000,
        } as AxiosRequestConfig, // axios-retry extends this to allow the 'axios-retry' param
      )
      .then((response) => response.data);

    return suggestions || [];
  } catch (e) {
    logError(e.message, {
      functionName: "getSearchSuggestions",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return [];
  }
};

export default getSearchSuggestions;
