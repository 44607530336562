import React, { HTMLAttributes } from "react";

export const Indicator: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <div
    className="
      absolute flex items-center justify-center
      pointer-events-none rounded-full bg-notification
      text-white text-xs leading-none
      opacity-100 transition-opacity
      min-h-[20px] min-w-[20px] top-[-8px] right-[-8px]
      empty:opacity-0
    "
    {...props}
  >
    {children}
  </div>
);
