import { HYDRATE } from "next-redux-wrapper";

import { RootState } from "./rootReducer";

/**
 * This action will be triggered on initial page load, as well as any client-side
 * transitions to pages that use `getServerSideProps` or `getStaticProps`.
 */
export type HydrateAction = {
  type: typeof HYDRATE;
  payload: RootState;
};

export type HydrationState = {
  hasHydrated: boolean;
};

export const initialState: HydrationState = {
  hasHydrated: false,
};

export default (
  state: HydrationState = initialState,
  action: HydrateAction,
): HydrationState => {
  switch (action.type) {
    case HYDRATE:
      return {
        hasHydrated: true,
      };
    default:
      return state;
  }
};

export { HYDRATE } from "next-redux-wrapper";
