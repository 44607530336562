const PRESIGNED_URL_HOSTS = [
  "leafly-integration.imgix.net",
  "leafly-production.imgix.net",
  "app.netsuite.com",
  "cdn.filepicker.io",
  "public.leafly.com",
];

export default (url: string) => {
  try {
    const { hostname } = new URL(url);
    return PRESIGNED_URL_HOSTS.some((host) => hostname.includes(host));
  } catch (e) {
    return false;
  }
};
