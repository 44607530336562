import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";

import { consumerApiErrorInterceptor } from "./consumer-api";

// Initialize the service request with the app and environment
const consumerApiCa = serviceRequest();

// Configure and set upstream URL
consumerApiCa.defaults.baseURL = publicConfig.services.consumerApiCa.url;
consumerApiCa.defaults.withCredentials = true;

consumerApiCa.interceptors.response.use(
  (response) => response,
  consumerApiErrorInterceptor,
);

export default consumerApiCa;
