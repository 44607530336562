"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { addSeconds } from "date-fns";
import { useSelector } from "react-redux";

import publicConfig from "config/public";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import useQueue from "hooks/useQueue";
import { getIsLoggedOutRedirect } from "redux/selectors/user";
import { getCookie, removeCookie, setCookie } from "utils/cookies";

import Snackbar from "components/Snackbar";

/**
 * This context enables the app to display a Snack Bar message
 * that pops up from the bottom of the screen.This can be done
 * in two ways:
 *
 * 1. A snack_message cookie that can accept a string,
 * but will only activate if it matches one of the messages in the
 * SnackBarMessages enum and its corresponding message object below.
 * To set the cookie in this app, use the addSnackBarCookie function.
 *
 * 2. Using the addSnackBarItem function from the useSnackBar
 * context hook that will also only activate if it matches one of the
 * enums.
 *
 * Snack bar items are then added to a queue with setTimeout functions
 * that display each snack bar message over a 4 second period in the
 * order that they were added.
 */

const SNACK_COOKIE_NAME = "snackbar_message";
const SNACK_TRANSITION_TIMEOUT = 300;
const SNACK_FULL_DURATION = 4000;
const SNACK_VISIBLE_DURATION = SNACK_FULL_DURATION - SNACK_TRANSITION_TIMEOUT;

export enum SnackBarMessages {
  accountCreated = "accountCreated",
  accountDeleted = "accountDeleted",
  favoriteAdded = "favoriteAdded",
  favoriteRemoved = "favoriteRemoved",
  dispensaryAdded = "dispensaryAdded",
  dispensaryRemoved = "dispensaryRemoved",
  dispensaryReviewSubmitted = "dispensaryReviewSubmitted",
  signedIn = "signedIn",
  reviewSubmitted = "reviewSubmitted",
  saveStrainPreferences = "saveStrainPreferences",
  resultsFilteredToDispensary = "resultsFilteredToDispensary",
  signedOut = "signedOut",
  linkCopiedToClipboard = "linkCopiedToClipboard",
  linkCopiedToClipboardError = "linkCopiedToClipboardError",
  brandFavoriteAdded = "brandFavoriteAdded",
  brandFavoriteRemoved = "brandFavoriteRemoved",
}

const SNACK_BAR_MESSAGING: Record<string, Record<string, string>> = {
  [SnackBarMessages.accountCreated]: {
    icon: "circle_check_mark.svg",
    message: "Account successfully created!",
  },
  [SnackBarMessages.accountDeleted]: {
    icon: "circle_check_mark.svg",
    message: "Your account has been deleted",
  },
  [SnackBarMessages.favoriteAdded]: {
    icon: "heart_filled.svg",
    message: "Strain added to favorites!",
  },
  [SnackBarMessages.favoriteRemoved]: {
    icon: "heart_outline.svg",
    message: "Strain removed from favorites!",
  },
  [SnackBarMessages.dispensaryAdded]: {
    icon: "circle_check_mark.svg",
    message: "Dispensary added to favorites!",
  },
  [SnackBarMessages.dispensaryRemoved]: {
    icon: "circle_check_mark.svg",
    message: "Dispensary removed from favorites!",
  },
  [SnackBarMessages.dispensaryReviewSubmitted]: {
    icon: "circle_check_mark.svg",
    message: "Thank you for submitting a review",
  },
  [SnackBarMessages.signedIn]: {
    icon: "circle_check_mark.svg",
    message: "You've successfully signed in!",
  },
  [SnackBarMessages.reviewSubmitted]: {
    icon: "circle_check_mark.svg",
    message: "Thank you for submitting a review!",
  },
  [SnackBarMessages.saveStrainPreferences]: {
    icon: "circle_check_mark.svg",
    message: "Your strain preferences have been saved!",
  },
  [SnackBarMessages.resultsFilteredToDispensary]: {
    icon: "circle_check_mark.svg",
    message: "Results filtered to dispensary cart",
  },
  [SnackBarMessages.signedOut]: {
    icon: "circle_check_mark.svg",
    message: "You've successfully signed out!",
  },
  [SnackBarMessages.linkCopiedToClipboard]: {
    icon: "circle_check_mark.svg",
    message: "Link copied to your clipboard",
  },
  [SnackBarMessages.linkCopiedToClipboardError]: {
    icon: "circle_x.svg",
    message: "Error when copying link to your clipboard",
  },
  [SnackBarMessages.brandFavoriteAdded]: {
    icon: "heart_filled.svg",
    message: "Brand added to favorites!",
  },
  [SnackBarMessages.brandFavoriteRemoved]: {
    icon: "circle_x.svg",
    message: "Brand removed from favorites.",
  },
};

type QueueItem = keyof typeof SNACK_BAR_MESSAGING;

type SnackBarContextProps = {
  addSnackBarItem: (queueItem: QueueItem) => void;
  addSnackBarCookie: (cookieValue: string) => void;
};

const SnackBarContext = createContext<SnackBarContextProps>({
  addSnackBarCookie: () => false,
  addSnackBarItem: () => false,
});

type SnackBarProviderProps = {
  children: React.ReactNode;
};

const { cookieDomainCa, cookieDomain } = publicConfig;

export const SnackBarProvider = ({ children }: SnackBarProviderProps) => {
  const [snackVisible, setSnackVisible] = useState<boolean>(false);
  const countryCode = useDomainCountryCode();
  const snackMessageCookie = getCookie(SNACK_COOKIE_NAME);

  const removeSnackCookie = () => {
    removeCookie(
      SNACK_COOKIE_NAME,
      countryCode === "CA" ? cookieDomainCa : cookieDomain,
    );
  };
  const queueFn = (queueItem: QueueItem, removeFromQueue: () => void) => {
    setSnackVisible(true);

    // Timer to determine visibility of snack message.
    // This is seprated from the queue timer below so that
    // the animation can complete before being removed
    // from the queue
    const visibilityTimer = setTimeout(() => {
      // If we get a snack message from the browser cookies
      // we need to remove it from the cookies to prevent
      // the snack message from showing up again
      if (snackMessageCookie) {
        removeSnackCookie();
      }

      setSnackVisible(false);
    }, SNACK_VISIBLE_DURATION);

    const queueTimer = setTimeout(() => {
      removeFromQueue();
    }, SNACK_FULL_DURATION);

    return () => {
      clearTimeout(visibilityTimer);
      clearTimeout(queueTimer);
    };
  };

  const [queue, addQueueItem] = useQueue(queueFn);

  const addSnackBarItem = (queueItem: QueueItem) => {
    if (Object.keys(SNACK_BAR_MESSAGING)?.includes(queueItem)) {
      addQueueItem(queueItem);
    } else {
      if (snackMessageCookie) {
        removeSnackCookie();
      }

      console.warn(
        "This snack message does not currently exist, add to SNACK_BAR_MESSAGING object above.",
      );
    }
  };

  useEffect(() => {
    if (snackMessageCookie) {
      const snacks = snackMessageCookie.split(",");
      snacks.forEach((item) => {
        addSnackBarItem(item);
      });
    }
  }, [snackMessageCookie]);

  const addSnackBarCookie = (cookieValue: string) => {
    const expires = addSeconds(new Date(), 15);
    setCookie(
      SNACK_COOKIE_NAME,
      cookieValue,
      countryCode === "CA" ? cookieDomainCa : cookieDomain,
      expires,
    );
  };

  const isLoggedOutRedirect = useSelector(getIsLoggedOutRedirect);
  useEffect(() => {
    if (isLoggedOutRedirect) {
      addSnackBarItem(SnackBarMessages.signedOut);
    }
  }, [isLoggedOutRedirect]);

  const snackInQueue = queue[0];

  return (
    <SnackBarContext.Provider
      value={{
        addSnackBarCookie,
        addSnackBarItem,
      }}
    >
      {children}

      <Transition
        className="
          fixed px-lg w-full bottom-0 z-20
          transition-all duration-300
        "
        enterFrom="opacity-0 translate-y-[60px]"
        enterTo="opacity-1 translate-y-0"
        leaveFrom="opacity-1 translate-y-0"
        leaveTo="opacity-0 translate-y-[60px]"
        show={snackVisible}
      >
        {SNACK_BAR_MESSAGING[snackInQueue]?.message && (
          <div className="w-full mx-auto mb-md max-w-[492px]">
            <Snackbar
              message={SNACK_BAR_MESSAGING[snackInQueue].message}
              iconFilePath={SNACK_BAR_MESSAGING[snackInQueue]?.icon}
            />
          </div>
        )}
      </Transition>
    </SnackBarContext.Provider>
  );
};

export const useSnackBar = (): SnackBarContextProps =>
  useContext(SnackBarContext);

export default SnackBarContext;
