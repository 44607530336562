import { memo, useMemo } from "react";
import cx from "classnames";

import generateImageSrcProps from "utils/image/generateImageSrcProps";

import { ImageProps } from "./Image";

type Props = Omit<
  ImageProps,
  "className" | "imgixParams" | "mobileSrc" | "src" | "tabletSrc"
> & {
  baseSrc: string;
  params?: Record<string, string>;
  setError: () => void;
};

export default memo(
  ({
    alt,
    background = false,
    backgroundFit = "cover",
    backgroundPosition = "center",
    baseSrc,
    disableLazyLoad,
    imageClass,
    params,
    ratio,
    setError,
    sizes,
    ...props
  }: Props) => {
    const imageClasses = cx(
      "max-w-full h-full w-full origin-center",
      imageClass,
      { absolute: ratio },
      { lazyload: !disableLazyLoad },
      { "no-lazyload": disableLazyLoad },
    );

    const imageStyles =
      background || ratio
        ? {
            objectFit: backgroundFit,
            objectPosition: backgroundPosition,
          }
        : {};

    const imageSrcProps = useMemo(
      () =>
        generateImageSrcProps(
          baseSrc,
          // There could be null values, so we need to filter out those
          // null values and then grab the last value just to make sure
          // an actual number is passed through
          sizes.filter((size) => size !== null).pop() as number,
          disableLazyLoad,
          params,
        ),
      [baseSrc, `${sizes}`, disableLazyLoad, `${params}`],
    );

    return (
      <img
        {...imageSrcProps}
        className={imageClasses}
        alt={alt}
        onError={setError}
        style={imageStyles}
        {...props}
      />
    );
  },
);
