import consumerApi from "api/services/consumer-api";
import consumerApiCa from "api/services/consumer-api-ca";
import { User, UserResponse } from "custom-types/User";
import logError from "utils/logError";
import sanitizeUserResponseObject from "utils/sanitizeUserResponseObject";

const getUser = async (countryCode = "US", cookie?: string): Promise<User> => {
  const api = countryCode === "CA" ? consumerApiCa : consumerApi;
  try {
    const { data } = await api.get<UserResponse>(
      `/api/profile/v1/user`,
      cookie ? { headers: { cookie } } : undefined,
    );

    return sanitizeUserResponseObject(data);
  } catch (e) {
    if (e.statusCode !== 401) {
      logError(e.message, {
        functionName: "getUser",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return { id: 0 };
  }
};

export default getUser;
