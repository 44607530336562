import Cookies from "js-cookie";

export const LOAD_COOKIES = "LOAD_COOKIES";

export type LoadCookiesAction = {
  type: typeof LOAD_COOKIES;
  cookies: {
    [key: string]: string;
  };
};

// This is done to keep the reducers "pure" from side effects. Grabbing cookies should be done outside of pure functions.
export const createLoadCookiesAction = (): LoadCookiesAction => {
  return {
    cookies: Cookies.get(),
    type: "LOAD_COOKIES",
  };
};
