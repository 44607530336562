import React from "react";

import Image from "components/Image";

type Size = number | null;
type Sizes = [number] | [Size, Size, Size, Size, Size, number];

type ImageFallbackProps = {
  sizes: Sizes;
  customImageFallback: string;
};

const ImageFallback: React.FC<ImageFallbackProps> = (props) => {
  const { customImageFallback } = props;

  return <Image alt="" src={customImageFallback} {...props} />;
};

export default ImageFallback;
