import omit from "lodash/omit";

import { User, UserResponse } from "custom-types/User";

export default (response: UserResponse): User => {
  // avatar, smsOptedInAt, subscribeSmsNotifications and
  // subscribeSmsNotificationsTermsVersion are not properties
  // currently used in this app.
  const userObject = omit(response, [
    "avatar",
    "smsOptedInAt",
    "subscribeSmsNotifications",
    "subscribeSmsNotificationsTermsVersion",
  ]);

  // The user object can/will have null values and we don't
  // need to store those. This removes any null values
  return Object.fromEntries(
    Object.entries(userObject).filter(([_, v]) => v !== null),
  );
};
