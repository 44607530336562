import { ReactNode, useCallback, useState } from "react";
import classNames from "classnames";

import ChevronIcon from "components/Icons/ChevronIcon";

const NavigationItemAccordion: React.FC<{
  children: ReactNode;
  section: string;
  trackLinkClick: (text: string) => void;
}> = ({ children, section, trackLinkClick }) => {
  const [open, setOpen] = useState(false);

  const handleDropDownClick = useCallback(() => {
    setOpen(!open);
    trackLinkClick(section);
  }, [open, trackLinkClick]);

  return (
    <li
      aria-labelledby={`nav__accordion--${section}`}
      aria-label={`Change ${section} navigation`}
      className="flex flex-col gap-xl"
    >
      <button
        aria-label={`Click to expand ${section}`}
        className="flex flex-1 font-medium justify-between"
        id={`nav__accordion--${section}`}
        aria-expanded={open}
        aria-controls={`nav__${section}`}
        onClick={handleDropDownClick}
      >
        {section}
        <ChevronIcon direction={open ? "up" : "down"} />
      </button>
      <ul
        aria-labelledby={`nav__accordion--${section}`}
        className={classNames("flex flex-col gap-xl py-lg bg-[#f9f9f9]", {
          "-mx-xl px-xl": open,
          hidden: !open,
        })}
        id={`nav__${section}`}
      >
        {children}
      </ul>
    </li>
  );
};

export default NavigationItemAccordion;
