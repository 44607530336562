import { Dispatch } from "redux";

import publicConfig from "config/public";
import { Location } from "custom-types/Location";
import {
  LOCATION_GEO_LOAD,
  mergeLocationProperties,
} from "redux/reducers/location";

const { cookieDomain, cookieDomainCa } = publicConfig;

/*
 * TODO: Consolidate this.
 * The existing cookie's format is different than the redux location state we are retaining.
 * Our code has been using a type, Coordinates, that is: { coordinates: { lat, lon }}
 * Our code also doesn't have snake_case properties.
 *
 * As a work around, this function will add those additional properties that other apps are consuming.
 */

// @ts-ignore (fix me please, do not replicate)
const fixPropertiesForCookie = (location) => ({
  ...location,
  coordinates: convertCoordinatesToCookie(location.coordinates),
  country_code: location.countryCode,
  formatted_location: location.formattedLocation,
  place_id: location.place_id || location.placeId,
  slug: location.slug || location.geoSlug,
});
// @ts-ignore (fix me please, do not replicate)
const convertCoordinatesToCookie = (coordinates) => ({
  latitude: coordinates.lat || coordinates.latitude,
  longitude: coordinates.lon || coordinates.longitude,
});

/*
 * IMPORTANT!
 * The normal cookie library we use, js-cookie, has it's own decoding/encoding mechanism that is not compatible with other parts of our system (non web-web apps)
 * The leafly-location cookie is used in many of our applications. We decode/encode it with the decode/encodeUriComponent function - which is not how js-cookie encodes/decodes.
 * This can cause weird bugs. So we intentionally chose not to use that library in this case.
 */
const setLocationCookie = (location: Location, countryCode: string) => {
  document.cookie = formatLocationCookie(location, countryCode);
};

export const formatLocationCookie = (
  location: Location,
  countryCode: string,
) => {
  // eslint-disable-next-line prettier/prettier -- Prettier does not like the no-mixed-operators eslint rule, but we do :)
  const expiration = new Date(new Date().getTime() + (86400 * 365 * 1000))
;
  return `leafly-location=${encodeURIComponent(
    JSON.stringify(
      //The shape of the cookie is different than some of our state, so we map properties to the shape of the cookie
      fixPropertiesForCookie(mergeLocationProperties(location)),
    ),
  )}; expires=${expiration.toUTCString()}; path=/; domain=${
    countryCode === "CA" ? cookieDomainCa : cookieDomain
  }`;
};

export const updateLocation = (dispatch: Dispatch, newLocation: Location) => {
  const countryCode =
    newLocation.country_code || newLocation.countryCode || "US";

  setLocationCookie(newLocation, countryCode);

  dispatch({
    location: newLocation,
    type: LOCATION_GEO_LOAD,
  });
};
