import React from "react";
import classNames from "classnames";

import useDomainCountryCode from "hooks/useDomainCountryCode";

type Props = {
  caDisclaimer?: string;
  usDisclaimer?: string;
  disclaimerOverride?: string;
  className?: string;
};

const MedicalDisclaimers: React.FC<Props> = ({
  caDisclaimer,
  usDisclaimer,
  disclaimerOverride,
  className = "",
}: Props) => {
  const countryCode = useDomainCountryCode();

  const disclaimer = countryCode === "CA" ? caDisclaimer : usDisclaimer;

  if (!disclaimer && !disclaimerOverride) {
    return null;
  }

  return (
    <div
      className={classNames(className, "p-sm rounded border border-light-grey")}
      data-testid="medical-disclaimer"
    >
      <p className="text-xs italic text-grey mb-none">
        {disclaimerOverride || disclaimer}
      </p>
    </div>
  );
};

export default MedicalDisclaimers;
