"use client";

import { useEffect } from "react";

import datadogInit from "lib/datadogInit";

const DatadogBrowserLogs = ({
  env,
  version,
}: {
  env?: string;
  version?: string;
}) => {
  useEffect(() => {
    if (env && version) {
      datadogInit({ env, version });
    }
  }, []);

  return null;
};

export default DatadogBrowserLogs;
