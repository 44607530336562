import { useEffect, useRef } from "react";
import round from "lodash/round";
import { useEventListener } from "usehooks-ts";
import {
  CLSMetric,
  INPMetric,
  LCPMetric,
  onCLS,
  onINP,
  onLCP,
} from "web-vitals";

import isMobileDevice from "utils/isMobileDevice";

const SAMPLE_RATE = 0.6;

// connection should exist, but TS doesn't know about it for some reason
type NavigatorWithConnection = Navigator & {
  connection: { effectiveType: "slow-2g" | "2g" | "3g" | "4g" };
};

type Metric = CLSMetric | INPMetric | LCPMetric;

export type CoreWebVitalsData = {
  deviceType: string;
  name: string;
  network?: string;
  page?: string;
  value: number;
};

const queue = new Set<CoreWebVitalsData>();

const DISPENSARIES_PAGES = ["/cbd-stores", "/medical-marijuana-doctors"];
const DISPENSARY_INFO_PAGES = ["/cannabis-store", "/cbd-store"];

const useCoreWebVitals = () => {
  const documentRef = useRef<Document | null>(
    typeof document !== "undefined" ? document : null,
  );

  if (typeof window !== "undefined" && !window.__NEXT_DATA__?.page) {
    /**
     * The `window.__NEXT_DATA__` object only exists for pages rendered by the Pages Router,
     * and there doesn't currently (as of September 2024) seem to be any way of getting the
     * generic route name (e.g. `/foo/bar/[id]`) on App Router pages. We need that generic
     * path in order to group pages together that use path params.
     *
     * This needs to be addressed before any App Router pages are deployed to production.
     */
    console.error(
      "Tracking CWV by page is currently only supported by Pages Router",
    );
  }

  const flushQueue = () => {
    if (queue.size > 0) {
      const body = JSON.stringify([...queue]);

      navigator?.sendBeacon?.("/api/metrics/core-web-vitals", body) ||
        fetch("/api/metrics/core-web-vitals", {
          body,
          keepalive: true,
          method: "POST",
        });

      queue.clear();
    }
  };

  const addToQueue = (metric: Metric) => {
    // Remove this when App Router is supported.
    if (!window?.__NEXT_DATA__?.page) {
      return;
    }

    let page = window.__NEXT_DATA__.page; // https://github.com/vercel/next.js/discussions/32221

    // No need to track these pages
    if (page.includes("404") || page.includes("500")) {
      return;
    }

    // These pages all use the same layout, so grouping them all together will
    // give us a better idea of how they're performing
    const isDispensariesPage = DISPENSARIES_PAGES.find((dispensaryPage) =>
      page?.startsWith(dispensaryPage),
    );
    const isDispensaryInfoPage = DISPENSARY_INFO_PAGES.find(
      (dispensaryInfoPage) => page?.startsWith(dispensaryInfoPage),
    );
    if (isDispensariesPage) {
      page = page.replace(isDispensariesPage, "/dispensaries");
    } else if (isDispensaryInfoPage) {
      page = page.replace(isDispensaryInfoPage, "/dispensary-info");
    }

    queue.add({
      deviceType: isMobileDevice() ? "mobile" : "desktop",
      name: metric.name,
      network: (navigator as NavigatorWithConnection)?.connection
        ?.effectiveType,
      page,
      value: round(
        metric.name === "LCP" ? metric.delta / 1000 : metric.delta,
        3,
      ),
    });
  };

  useEffect(() => {
    if (Math.random() <= SAMPLE_RATE) {
      onCLS(addToQueue);
      onINP(addToQueue);
      onLCP(addToQueue);
    }
  }, []);

  // https://www.npmjs.com/package/web-vitals#batch-multiple-reports-together
  useEventListener(
    "visibilitychange",
    () => document.visibilityState === "hidden" && flushQueue(),
    documentRef,
  );
};

export default useCoreWebVitals;
