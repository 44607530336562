"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * These configuration options are either:
 * - Extremely sensitive and should not be expose to client-side code
 * - Only available at runtime, so cannot be included in the client-side bundle.
 *
 * Be extremely intentional about using these values, such that they are not
 * exposed to the public.
 *
 *                ░░░░
 *
 *                                            ██
 *                                          ██░░██
 *  ░░          ░░                        ██░░░░░░██                            ░░░░
 *                                      ██░░░░░░░░░░██
 *                                      ██░░░░░░░░░░██
 *                                    ██░░░░░░░░░░░░░░██
 *                                  ██░░░░░░██████░░░░░░██
 *                                  ██░░░░░░██████░░░░░░██
 *                                ██░░░░░░░░██████░░░░░░░░██
 *                                ██░░░░░░░░██████░░░░░░░░██
 *                              ██░░░░░░░░░░██████░░░░░░░░░░██
 *                            ██░░░░░░░░░░░░██████░░░░░░░░░░░░██
 *                            ██░░░░░░░░░░░░██████░░░░░░░░░░░░██
 *                          ██░░░░░░░░░░░░░░██████░░░░░░░░░░░░░░██
 *                          ██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
 *                        ██░░░░░░░░░░░░░░░░██████░░░░░░░░░░░░░░░░██
 *                        ██░░░░░░░░░░░░░░░░██████░░░░░░░░░░░░░░░░██
 *                      ██░░░░░░░░░░░░░░░░░░██████░░░░░░░░░░░░░░░░░░██
 *        ░░            ██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
 *                        ██████████████████████████████████████████
 *
 *
 *
 *
 *                  ░░
 */
exports.default = {
    apiOgInternalApiKey: process.env.API_OG_INTERNAL_API_KEY || "",
    branch: process.env.LEAFLY_BRANCH || "",
    datadogEnvironment: process.env.DD_ENV || "",
    datadogVersion: process.env.DD_VERSION || "",
    developmentDomainCountryCode: process.env.COUNTRY_CODE || "US",
    encryption: {
        key: process.env.ENCRYPTION_KEY || "",
    },
    env: process.env.LEAFLY_ENV || "local-dev",
    googleMapsServerApiKey: process.env.GOOGLE_MAPS_SERVER_API_KEY || "",
    leaflyOauth: {
        clientId: process.env.LEAFLY_CLIENT_CREDENTIALS_ID || "service-web-web-vrwy09",
        clientSecret: process.env.LEAFLY_CLIENT_CREDENTIALS_SECRET || "",
        tokenUrl: process.env.LEAFLY_CLIENT_CREDENTIALS_TOKEN_ENDPOINT ||
            "https://sso-integration.leafly.io/token",
    },
    leaflyTokeCookie: process.env.LEAFLY_TOKE_SESSION_COOKIE || "",
    sha: process.env.LEAFLY_SHA || "",
    statsd: process.env.DD_AGENT_HOST || "",
    /**
     * Setting this environment variable will disable the Next.js signal handlers
     * and allow us to install our own in support of gracefully shutting down
     * the application process as pods are stopped. There is no need to set this
     * variable in the development enviroment.
     *
     * See:
     * - https://nextjs.org/docs/deployment#manual-graceful-shutdowns
     * - https://github.com/vercel/next.js/blob/6d09f0b80c6ce3e956733492a9c56bf1159f513b/packages/next/src/bin/next.ts#L114-L119
     */
    useManualSignalHandler: Boolean(process.env.NEXT_MANUAL_SIG_HANDLE),
    wordpress: {
        password: process.env.WORDPRESS_PASSWORD || "",
        url: process.env.WORDPRESS_URL || "https://www-integration.leafly.io",
        username: process.env.WORDPRESS_USERNAME || "wp-api",
    },
};
