import React from "react";
import Link from "next/link";

import { Action, Category } from "constants/events";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";

export const HeaderLinks = () => {
  const countryCode = useDomainCountryCode();

  const { publishEvent } = useEventTracker();

  const trackLinkClick = (eventLabel: string) => {
    publishEvent({
      action: Action.click,
      category: Category.header,
      label: eventLabel,
    });
  };

  const linkClasses = `mr-md mt-md font-bold whitespace-nowrap hover:underline`;

  return (
    <>
      <nav
        className="header__links hidden lg:flex overflow-x-auto whitespace-nowrap transition-[height] motion-reduce:transition-none"
        id="global-header-nav-links"
        aria-label="Popular pages"
      >
        <Link
          className={linkClasses}
          data-testid="shop"
          href="/shop?fulfillmentPreference=pickup"
          onClick={() => {
            trackLinkClick("shop");
          }}
        >
          Shop
        </Link>

        <Link
          className={linkClasses}
          data-testid="delivery"
          href="/delivery"
          onClick={() => {
            trackLinkClick("delivery");
          }}
        >
          Delivery
        </Link>

        <Link
          className={linkClasses}
          data-testid="dispensaries"
          href="/dispensaries"
          onClick={() => {
            trackLinkClick("dispensaries");
          }}
        >
          {countryCode === "CA" ? "Stores" : "Dispensaries"}
        </Link>

        <Link
          className={linkClasses}
          data-testid="deals"
          href="/deals"
          onClick={() => {
            trackLinkClick("deals");
          }}
        >
          Deals
        </Link>

        <Link
          className={linkClasses}
          data-testid="strains"
          href="/strains/lists"
          onClick={() => {
            trackLinkClick("strains");
          }}
        >
          Strains
        </Link>

        <Link
          className={linkClasses}
          data-testid="brands"
          href="/brands"
          onClick={() => {
            trackLinkClick("brands");
          }}
        >
          Brands
        </Link>

        <Link
          className={linkClasses}
          data-testid="products"
          href="/products"
          onClick={() => {
            trackLinkClick("products");
          }}
        >
          Products
        </Link>

        {countryCode !== "CA" && (
          <Link
            className={linkClasses}
            data-testid="cbd"
            href="/cbd-stores"
            onClick={() => {
              trackLinkClick("cbd");
            }}
          >
            CBD
          </Link>
        )}

        <Link
          className={linkClasses}
          data-testid="doctors"
          href={"/medical-marijuana-doctors"}
          onClick={() => {
            trackLinkClick("doctors");
          }}
        >
          Doctors
        </Link>

        <Link
          className={linkClasses}
          data-testid="cannabis-101"
          href="/news/cannabis-101"
          onClick={() => {
            trackLinkClick("cannabis 101");
          }}
        >
          Cannabis 101
        </Link>

        <Link
          className={linkClasses}
          data-testid="social-impact"
          href="/social-impact"
          onClick={() => {
            trackLinkClick("social impact");
          }}
        >
          Social impact
        </Link>
      </nav>
      <style jsx>{`
        .header__links {
          scrollbar-width: none; /* Firefox */
        }
        .header__links::-webkit-scrollbar {
          display: none;
        }
        @media (max-width: 767px) {
          #global-header-nav-links {
            /* Warning: This value needs to be updated when the size of the input changes! */
            height: 40px;
          }
        }
      `}</style>
    </>
  );
};
