export const decode = (
  b64String: string,
): { [key: string]: string | number | boolean | undefined } => {
  return JSON.parse(atob(decodeURIComponent(b64String)));
};

export const encode = (object: {
  [key: string]: string | number | boolean | undefined;
}): string => {
  return btoa(JSON.stringify(object));
};
