import { type AxiosError, type AxiosRequestHeaders } from "axios";

import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";
import formatAndReturnRejectedError from "utils/formatAndReturnRejectedError";

// Initialize the service request with the app and environment
const userNotificationApi = serviceRequest();

// Configure and set upstream URL
userNotificationApi.defaults.baseURL =
  publicConfig.services.userNotificationApi.url;
userNotificationApi.defaults.withCredentials = true;

export const userNotificationErrorInterceptor = (
  error: AxiosError<{ status: number; message?: string }>,
) =>
  formatAndReturnRejectedError(
    error,
    error.response?.data?.message,
    error.response?.data?.status,
  );

userNotificationApi.interceptors.response.use(
  (response) => response,
  userNotificationErrorInterceptor,
);

const headers = userNotificationApi.defaults
  .headers as unknown as AxiosRequestHeaders;

// notificaitons api does not like these
delete headers["X-App"];
delete headers["X-Environment"];

export default userNotificationApi;
