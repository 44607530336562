import publicConfig from "config/public";

import useDomainCountryCode from "./useDomainCountryCode";

const { cookieDomain, cookieDomainCa } = publicConfig;

export const getCookieDomainFromContext = (countryCode: string) =>
  countryCode === "CA" ? cookieDomainCa : cookieDomain;

export const useCookieDomain = () =>
  getCookieDomainFromContext(useDomainCountryCode());
