import React from "react";

import useDomainCountryCode from "hooks/useDomainCountryCode";

import { FooterLink } from "./FooterLink";
import { ResponsiveAccordion } from "./ResponsiveAccordion";

export const DispensaryQuickLinks: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  const isCanada = useDomainCountryCode() === "CA";

  return (
    <ResponsiveAccordion
      label={isCanada ? "Stores in" : "Dispensaries in"}
      defaultExpanded={false}
      isResponsive={isResponsive}
    >
      <ul className="font-medium text-sm text-green font-bold">
        {isCanada ? (
          <>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/british-columbia"
                trackingLabel="british columbia stores"
              >
                British Columbia
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/ontario"
                trackingLabel="ontario stores"
              >
                Ontario
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/alberta"
                trackingLabel="alberta stores"
              >
                Alberta
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/saskatchewan"
                trackingLabel="saskatchewan stores"
              >
                Saskatchewan
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/manitoba"
                trackingLabel="manitoba stores"
              >
                Manitoba
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/prince-edward-island"
                trackingLabel="prince edwards island stores"
              >
                Prince Edward Island
              </FooterLink>
            </li>
          </>
        ) : (
          <>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/california/los-angeles"
                trackingLabel="los angeles dispensaries"
              >
                Los Angeles
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/washington/seattle"
                trackingLabel="seattle dispensaries"
              >
                Seattle
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/oregon/portland"
                trackingLabel="portland dispensaries"
              >
                Portland
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/california/san-francisco"
                trackingLabel="san francisco dispensaries"
              >
                San Francisco
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="https://leafly.ca/dispensaries/ontario/toronto"
                trackingLabel="toronto dispensaries"
              >
                Toronto
              </FooterLink>
            </li>
            <li className="py-sm">
              <FooterLink
                href="/dispensaries/michigan/detroit"
                trackingLabel="detroit dispensaries"
              >
                Detroit
              </FooterLink>
            </li>
          </>
        )}
      </ul>
    </ResponsiveAccordion>
  );
};
