import { usePathname, useSearchParams } from "next/navigation";

import { Query } from "custom-types/Query";
import {
  generateBaseUrl,
  generateSignInUrl,
  generateSignOutUrl,
  generateSignUpUrl,
} from "utils/generateSsoUrls";

import useDomainCountryCode from "./useDomainCountryCode";

type Prop = {
  redirectUrlParams?: Query;
  redirectPath?: string;
  ssoUrlParams?: Query;
};

type Props = {
  base?: Prop;
  signIn?: Prop;
  signOut?: Prop;
  signUp?: Prop;
};

const generateRedirectPath = (
  path: string,
  baseParams: URLSearchParams,
  params: Query = {},
) => {
  const queryString = new URLSearchParams(baseParams);

  for (const [key, value] of Object.entries(params)) {
    queryString.set(key, String(value || ""));
  }

  return `${path}?${queryString}`.replace(/\?$/, "");
};

/*
 * This hook is used to generate SSO URLs for the current domain country code.
 * It returns the base URL, sign in URL, sign out URL, and sign up URL.
 *
 * For each URL, you can assign the following props:
 * - redirectUrlParams: which combines the passed object with the current
 *  query params and appends them to the current path
 * - redirectPath: which overrides the current path
 * - ssoUrlParams: which appends the passed object as a query string to the SSO URL itself
 */
export const useSsoUrls = (props?: Props) => {
  /**
   * The `usePathname` and `useSearchParams` hooks may return null before a
   * statically-optimized page is hydrated. In that case, we can temporarily use
   * the root path as the redirect path, which will be replaced with the actual
   * path and params once hydration is complete.
   * See: https://nextjs.org/docs/app/api-reference/functions/use-pathname
   */
  const pathname = usePathname() || "/";
  const searchParams = new URLSearchParams(useSearchParams() || {});
  const domainCountryCode = useDomainCountryCode();

  return {
    baseUrl: generateBaseUrl(
      domainCountryCode,
      generateRedirectPath(
        props?.base?.redirectPath || pathname,
        searchParams,
        props?.base?.redirectUrlParams,
      ),
      props?.base?.ssoUrlParams,
    ),
    signInUrl: generateSignInUrl(
      domainCountryCode,
      generateRedirectPath(
        props?.signIn?.redirectPath || pathname,
        searchParams,
        props?.signIn?.redirectUrlParams,
      ),
      props?.signIn?.ssoUrlParams,
    ),
    signOutUrl: generateSignOutUrl(
      domainCountryCode,
      generateRedirectPath(
        props?.signOut?.redirectPath || pathname,
        searchParams,
        props?.signOut?.redirectUrlParams,
      ),
      props?.signOut?.ssoUrlParams,
    ),
    signUpUrl: generateSignUpUrl(
      domainCountryCode,
      generateRedirectPath(
        props?.signUp?.redirectPath || pathname,
        searchParams,
        props?.signUp?.redirectUrlParams,
      ),
      props?.signUp?.ssoUrlParams,
    ),
  };
};
