import Cookies from "js-cookie";

const cookiesEnabled = () => {
  return (
    typeof document !== "undefined" &&
    typeof navigator !== "undefined" &&
    navigator.cookieEnabled
  );
};

export const getCookie = (name: string): string | undefined => {
  if (cookiesEnabled()) {
    return Cookies.get(name);
  }
};

export const setCookie = (
  cookieName: string,
  value: string | boolean,
  domain: string,
  expires: number | Date = 365, // days
  path = "/",
): void => {
  if (cookiesEnabled()) {
    Cookies.set(cookieName, String(value), {
      domain,
      expires,
      path,
    });
  }
};

export const removeCookie = (
  name: string,
  domain: string,
  path = "/",
): void => {
  if (cookiesEnabled()) {
    Cookies.remove(name, { domain, path });
  }
};
