import { Article } from "custom-types/Article";
import { Brand } from "custom-types/Brand";
import { Dispensary } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import { SearchPageTypes } from "custom-types/Search";
import { Strain } from "custom-types/Strain";
import { HYDRATE, HydrateAction } from "redux/reducers/hydration";

export const MAX_DISTANCE = 30; // 30 miles
export const DEFAULT_SEARCH_TAKE = 12;
export const DEFAULT_SEARCH_SKIP = 0;

export type SearchState = {
  article: Article[];
  brand: Brand[];
  categoryRelevanceOrder: SearchPageTypes[];
  dispensary: Dispensary[];
  loading: boolean;
  menuItem: MenuItem[];
  menuItemDispensaries: Dispensary[];
  strain: Strain[];
  totalCounts?: {
    article?: number;
    brand?: number;
    dispensary?: number;
    menuItem?: number;
    strain?: number;
  };
};

export type SearchResults = Omit<SearchState, "loading">;

export const initialState: SearchState = {
  article: [],
  brand: [],
  categoryRelevanceOrder: [
    "strain",
    "menuItem",
    "dispensary",
    "brand",
    "article",
  ],
  dispensary: [],
  loading: true,
  menuItem: [],
  menuItemDispensaries: [],
  strain: [],
  totalCounts: {
    article: 0,
    brand: 0,
    dispensary: 0,
    menuItem: 0,
    strain: 0,
  },
};

export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const SEARCH_LOAD_MORE = "SEARCH_LOAD_MORE";

export type SearchLoadingAction = {
  type: typeof SEARCH_LOADING;
};

export type SearchResultsAction = {
  type: typeof SEARCH_RESULTS;
  results: SearchResults;
};

export type SearchLoadMoreAction = {
  type: typeof SEARCH_LOAD_MORE;
  result: Partial<SearchResults>;
};

export type SearchActions =
  | SearchLoadingAction
  | SearchResultsAction
  | SearchLoadMoreAction
  | HydrateAction;

export const searchReducer = (
  state = initialState,
  action: SearchActions,
): SearchState => {
  switch (action.type) {
    case HYDRATE:
      /**
       * Ignore any hydration events after initial page load to preserve any
       * subsequent changes that have been made on the client.
       */
      return state;
    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_RESULTS:
      return {
        ...state,
        loading: false,
        ...action.results,
      };
    case SEARCH_LOAD_MORE:
      return {
        ...state,
        ...action.result,
      };
    default:
      return state;
  }
};

export default searchReducer;
