import axios from "axios";

import {
  reservationsApi,
  ReservationsApiErrorsResponse,
} from "api/services/reservations";
import { Cart, CartChange, CartContext } from "custom-types/Cart";
import { CountryCode } from "custom-types/CountryCode";
import logError from "utils/logError";

type retrieveCartOptions = {
  cartContext?: CartContext | null;
  cartId?: string | null;
  domainCountryCode?: CountryCode;
  updateOnLoad?: boolean;
};

export const retrieveCart = async ({
  cartContext = null,
  cartId = null,
  domainCountryCode = "US",
  updateOnLoad = false,
}: retrieveCartOptions): Promise<{
  cart: Cart | null;
  changes: CartChange[] | null;
  error?: Error;
}> => {
  const emptyCartState = {
    cart: null,
    changes: null,
  };

  if (!cartId) {
    return emptyCartState;
  }

  // Set the method to retrieve the cart based on parameter values.
  // GET: Default to using GET to retrieve the cart as that is the basic endpoint
  //    that retrieves the cart.
  // PUT: Used when reordering to update & fetch the cart, so that any messaging
  //    from the server on what changed in the cart is retained.
  // POST: This is used to retrieve the cart with cart context to calculate taxes
  //    as accurately as possible. POST is used because a GET method can not
  //    include a body payload containing the cart context.
  let fetchCartMethod = "GET";
  fetchCartMethod = updateOnLoad ? "PUT" : fetchCartMethod;
  fetchCartMethod = cartContext && !updateOnLoad ? "POST" : fetchCartMethod;
  const calculateTax = !!cartContext;
  const headers: Record<string, string> = {};
  headers["X-Leafly-Id"] = cartId;

  try {
    const { data } = await reservationsApi({
      domainCountryCode: domainCountryCode,
    }).request({
      data: {
        cartContext: cartContext,
      },
      headers: headers,
      method: fetchCartMethod,
      url: `/v2/cart?calculate_tax=${calculateTax}`,
    });

    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const errorData = (e.response?.data as ReservationsApiErrorsResponse)
        ?.errors;
      if (errorData) {
        errorData.forEach((error) => {
          // We don't need to log this error
          if (
            error.detail !==
            "Validation failed: Dispensary must accept reservations"
          ) {
            logError(error.detail, {
              functionName: "retrieveCart",
              service: "reservations",
            });
          }
        });
      } else if (e.response?.status !== 404) {
        logError(
          (e.response?.statusText !== "" && e.response?.statusText) ||
            e.message,
          {
            functionName: "retrieveCart",
            service: "reservations",
            statusCode: e.response?.status || e.code || "UNKNOWN",
          },
        );
      }
    } else {
      logError("reservationUnknownError", {
        functionName: "retrieveCart",
        service: "reservations",
      });
    }

    return { ...emptyCartState, error: e };
  }
};
