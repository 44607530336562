"use client";

import { useDispatch, useSelector } from "react-redux";

import { useEvent } from "hooks/useEvent";
import { RootState } from "redux/reducers/rootReducer";
import {
  Weedfetti,
  WEEDFETTI_CLEANUP,
  WEEDFETTI_LAUNCH,
} from "redux/reducers/weedfetti";

export const useWeedfetti = (): {
  cleanup: (id: string) => void;
  instances: Weedfetti[];
  launchWeedfetti: (options?: { colors?: string[] }) => void;
} => {
  const dispatch = useDispatch();
  const instances = useSelector(
    (state: RootState) => state.weedfetti.instances,
  );
  const cleanup = useEvent((id: string) => {
    dispatch({ type: WEEDFETTI_CLEANUP, value: id });
  });
  const launchWeedfetti = useEvent(({ colors }: { colors?: string[] } = {}) => {
    dispatch({ type: WEEDFETTI_LAUNCH, value: { colors } });
  });

  return {
    cleanup,
    instances,
    launchWeedfetti,
  };
};
