import { Coordinates } from "custom-types/Coordinates";
import { Location } from "custom-types/Location";
import { LOAD_COOKIES, LoadCookiesAction } from "redux/action-creators/cookies";
import { HYDRATE, HydrateAction } from "redux/reducers/hydration";
import logError from "utils/logError";

export type LocationState = {
  coordinates: Coordinates;
  countryCode: string;
  city: string;
  county?: string;
  defaultLocation: boolean;
  deliveryAddress?: Location;
  formattedLocation: string;
  formattedAddress?: string;
  geoSlug: string;
  isUserLocation?: boolean;
  locationLoadedFromCookie: boolean;
  requestingLocation: boolean;
  place_id?: string;
  state: string;
  street?: {
    number: string;
    name: string;
  };
  zip: string;
};

export const initialState: LocationState = {
  city: "Seattle",
  coordinates: {
    lat: 47.6062,
    lon: -122.3321,
  },
  countryCode: "US",
  defaultLocation: true,
  deliveryAddress: {},
  formattedLocation: "Seattle, WA",
  geoSlug: "seattle-wa-us",
  locationLoadedFromCookie: false,
  requestingLocation: false,
  state: "Washington",
  zip: "98164",
};

export const LOCATION_GEO_LOAD = "location/geo/load";
export const SET_USER_DELIVERY_ADDRESS = "location/setUserDeliveryAddress";
export const REQUEST_LOCATION = "location/request";
export const CANCEL_REQUEST_LOCATION = "location/request/cancel";

export type LocationLoadAction = {
  type: typeof LOCATION_GEO_LOAD;
  location: Location;
};

export type RequestUserLocation = {
  type: typeof REQUEST_LOCATION;
};

export type CancelRequestUserLocation = {
  type: typeof CANCEL_REQUEST_LOCATION;
};

export type SetUserDeliveryAddressAction = {
  type: typeof SET_USER_DELIVERY_ADDRESS;
  address: Location;
};

export type LocationActions =
  | LocationLoadAction
  | LoadCookiesAction
  | RequestUserLocation
  | CancelRequestUserLocation
  | SetUserDeliveryAddressAction
  | HydrateAction;

// @ts-ignore (fix me please, do not replicate)
const mergeCoordinates = (coordinates): Coordinates => ({
  lat: coordinates.lat || coordinates.latitude,
  lon: coordinates.lon || coordinates.longitude,
});

const formatAddress = (
  streetNumber: string,
  streetName: string,
  city: string,
  state: string,
) => {
  if (!!streetNumber && !!streetName && !!city && !!state) {
    return `${streetNumber} ${streetName}, ${city}, ${state}`;
  }
};

// @ts-ignore (fix me please, do not replicate)
export const mergeLocationProperties = (location) => {
  return {
    addressLine1: location.addressLine1,
    addressLine2: location.addressLine2,
    city: location.city,
    coordinates: mergeCoordinates(location.coordinates || location.location),
    country:
      location.country || location.countryCode || location.country_code || "",
    countryCode: location.countryCode || location.country_code || "",
    ...(location?.county ? { county: location?.county } : {}),
    formattedAddress:
      location.formattedAddress ||
      formatAddress(
        location.street?.number,
        location.street?.name,
        location.city,
        location.state,
      ),
    formattedLocation:
      location.formattedLocation || location.formatted_location || "",
    geoSlug: location.slug || location.geoSlug || "",
    isUserLocation: location.isUserLocation,
    place_id: location.place_id || location.placeId,
    state: location.state,
    state_code: location.state_code,
    street: location.street,
    zip: location.zip,
  };
};

const locationReducer = (
  state = initialState,
  action: LocationActions,
): LocationState => {
  let leaflyLocationCookie;
  switch (action.type) {
    case HYDRATE:
      /**
       * Ignore any hydration events after initial page load to preserve any
       * subsequent changes that have been made on the client.
       */
      return state;
    case LOAD_COOKIES:
      leaflyLocationCookie =
        action.cookies && action.cookies["leafly-location"];
      if (leaflyLocationCookie) {
        let location;

        try {
          location = JSON.parse(decodeURIComponent(leaflyLocationCookie));
        } catch (e) {
          logError("Unable to parse location cookie in locationReducer");
        }

        if (location?.coordinates?.latitude) {
          return {
            ...state,
            ...mergeLocationProperties(location),
            defaultLocation: false,
            locationLoadedFromCookie: true,
            street: location.street,
          };
        }
      }

      return {
        ...state,
        locationLoadedFromCookie: !!leaflyLocationCookie,
      };
    case LOCATION_GEO_LOAD:
      if (action.location) {
        const { location } = action;
        return {
          ...state,
          ...mergeLocationProperties(location),
          defaultLocation: false,
        };
      }
      break;
    case REQUEST_LOCATION:
      return {
        ...state,
        requestingLocation: true,
      };
    case CANCEL_REQUEST_LOCATION:
      return {
        ...state,
        requestingLocation: false,
      };

    case SET_USER_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: {
          addressLine1: action.address.addressLine1 || "",
          addressLine2: action.address.addressLine2 || "",
          city: action.address.city || "",
          country: action.address.country || "",
          countryCode: action.address.countryCode || "",
          ...(action.address.county ? { county: action.address.county } : {}),
          formattedAddress: action.address.formattedAddress || "",
          latitude: action.address.latitude,
          longitude: action.address.longitude,
          postalCode: action.address.postalCode,
          state: action.address.state || "",
          state_code: action.address.state_code || "",
          street: {
            name: (action.address.street || {}).name || "",
            number: (action.address.street || {}).number || "",
          },
        },
      };
    default:
      return state;
  }

  return state;
};

export default locationReducer;
