"use client";

import { useCallback, useRef } from "react";

import { useLayoutEffect } from "./useLayoutEffect";

/**
 * This hook allows a callback function to be defined that keeps the same
 * identity even as dependencies change. This can help prevent rerenders
 * and make using react state easier to work with in event listeners
 * attached to document or window.
 *
 * Functions returned from useEvent should not be called during render,
 * and are only available after render has completed client-side.
 *
 * Event functions do not need to be declared in dependency lists, as
 * they are considered "stable"; the function identity will not change,
 * even as the result of calling the function is modified by state updates.
 *
 * This hook is being proposed for addition to React-core, and this file
 * can be considered a polyfill as that proposal advances towards
 * standardization and implementation.
 *
 * See: https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export function useEvent<T extends readonly unknown[], U>(
  handler: (...args: T) => U,
) {
  const handlerRef = useRef<(...args: T) => U>();

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: T) => {
    const fn = handlerRef.current;

    if (!fn) {
      throw new Error(
        "Must not call a function defined in useEvent during render",
      );
    }

    return fn(...args);
  }, []);
}
