"use client";

import React, { HTMLAttributes } from "react";
import { useSelector } from "react-redux";

import { getNotificationCount } from "redux/selectors/header";

import { Indicator } from "./Indicator";

export const NotificationIndicator: React.FC<HTMLAttributes<HTMLDivElement>> = (
  props,
) => {
  const unreadCount = useSelector(getNotificationCount);

  if (!unreadCount || Number(unreadCount) === 0) {
    return null;
  }

  return (
    <Indicator {...props}>
      {Number(unreadCount) > 9 ? "9+" : unreadCount}
    </Indicator>
  );
};
