import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import dynamic from "next/dynamic";
import { useDispatch, useSelector, useStore } from "react-redux";

import getSearchSuggestions from "api/requests/consumerApi/getSearchSuggestions";
import { useChildrenBlurEvent } from "hooks/useChildrenBlurEvent";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import {
  CLOSE_SEARCH,
  OPEN_SEARCH,
  SUBMIT_SEARCH,
  UPDATE_SEARCH_SUGGESTIONS,
} from "redux/reducers/header";
import { RootState } from "redux/reducers/rootReducer";
import { getSearchOpen } from "redux/selectors/header";
import { getCoordinates } from "redux/selectors/location";
import { availableStorage } from "utils/storage";

import SearchIcon from "components/Icons/search.svg";
import CloseIcon from "components/Icons/x.svg";

const SearchOverlay = dynamic(() => import("components/Header/SearchOverlay"));

export const SearchBar: React.FC = () => {
  const countryCode = useDomainCountryCode();
  const location = useSelector(getCoordinates);
  const dispatch = useDispatch();
  const searchIsOpen = useSelector(getSearchOpen);
  const store = useStore();
  const [searchText, setSearchText] = useState<string | null>();
  const { publishEvent } = useEventTracker();

  const openSearch = () => {
    publishEvent({
      action: "click",
      category: "header search",
      label: "open",
    });

    dispatch({
      searchText,
      storage: availableStorage(),
      type: OPEN_SEARCH,
    });
  };

  const closeSearch = () => {
    publishEvent({
      action: "click",
      category: "header search",
      label: "close",
    });
    dispatch({
      type: CLOSE_SEARCH,
    });
  };

  const onSearchSubmit = () => {
    publishEvent({
      action: "click",
      category: "header search",
      label: "search executed",
    });
    dispatch({
      searchText,
      storage: availableStorage(),
      type: SUBMIT_SEARCH,
    });
  };

  const retrieveSearchSuggestions = () => {
    if (searchText && searchText.length > 2) {
      getSearchSuggestions(location, countryCode, searchText).then(
        (suggestions) => {
          dispatch({
            suggestions: suggestions?.map((s) => ({
              distanceMi: s.distanceMi,
              iconFilePath: "search.svg",
              id: s.id,
              link: s.link,
              subtitle: s.type,
              title: s.title,
            })),
            type: UPDATE_SEARCH_SUGGESTIONS,
          });
        },
      );

      if (!searchIsOpen) {
        // User can hit escape, then type new text in the input - which should open the beard up again.
        dispatch({
          searchText,
          storage: availableStorage(),
          type: OPEN_SEARCH,
        });
      }
    } else {
      // This is for when you delete your text and should see the recent search history again
      dispatch({
        suggestions: [],
        type: "header/updateSearchSuggestions",
      });
    }
  };

  const onSearch = debounce(retrieveSearchSuggestions);

  const { ref } = useChildrenBlurEvent<HTMLDivElement>(() => {
    // Note: You can't use a useSelector hook here - the value won't update. This calls the selector manually.
    if (getSearchOpen(store.getState() as RootState)) {
      closeSearch();
    }
  });

  useEffect(() => {
    onSearch();
  }, [searchText]);

  return (
    <div
      id="global-search"
      className="flex-grow basis-full h-[50px] md:h-auto lg:basis-[initial] lg:relative lg:mr-xxl order-[20] lg:order-[0] overflow-hidden md:overflow-visible transition-[height] motion-reduce:transition-none"
      ref={ref}
      data-testid="global-search-container"
    >
      <form
        id="global-search__form"
        className="w-full mt-md lg:mt-none transition-transform motion-reduce:transition-none"
        onSubmit={onSearchSubmit}
        method="get"
        action="/search"
        role="search"
      >
        <div className="relative bg-white form__field">
          <input
            className="pr-[74px] text-sm border rounded bg-leafly-white border-light-grey py-xs lg:bg-white focus:bg-leafly-white"
            placeholder={`Stores, strains, products...`}
            name="q"
            type="search"
            autoComplete="off"
            onChange={(e) => setSearchText(e.target.value)}
            onFocus={openSearch}
            aria-label="Search Leafly"
            data-testid="global-search-input"
            required
          />

          <div className="absolute top-0 right-0 flex h-full text-default">
            <button
              id="global-search__clear"
              className="hidden px-sm"
              type="button"
              aria-label="Clear search"
            >
              <CloseIcon
                className="flex-shrink-0 block search__icon"
                height="18"
                width="18"
              />
            </button>
            <button
              id="global-search__submit"
              className="px-sm"
              type="submit"
              aria-label="Search Leafly"
            >
              <SearchIcon
                className="flex-shrink-0 block search__icon"
                height="24"
                width="24"
              />
            </button>
          </div>
        </div>
      </form>
      {searchIsOpen && <SearchOverlay closeSearch={closeSearch} />}
    </div>
  );
};
