export default (url: string) => {
  let isDecoded;
  try {
    isDecoded = decodeURI(url) === url;
  } catch {
    // If url can't be decoded, assume it is already
    isDecoded = true;
  }

  if (isDecoded) {
    try {
      return encodeURI(url);
    } catch {
      return url;
    }
  } else {
    return url;
  }
};
