"use client";

import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import classNames from "classnames";
import FocusLock from "react-focus-lock";
import { useSwipeable } from "react-swipeable";

import useWindowSize from "hooks/useWindowSize";

import CloseIcon from "components/Icons/x.svg";

import { useModalContext } from "./internal/ModalContext";
import { useModalRenderContext } from "./internal/ModalRenderContext";

const SWIPE_TOLERANCE = 150; // px
const SWIPE_RESET_DURATION = 100; // ms

const MOBILE_BREAKPOINT = 765; // px
const TRANSITION_DURATION_TRANSFORM = 350; // ms
const TRANSITION_DURATION_OPACITY = 200; // ms

const noop = () => {
  // do nothing
};

export type ModalProps = {
  /**
   * An aria-labelledby property is required for accessibility, unless a title
   * is supplied. This should be the ID of an element in the modal which labels
   * the modal; this element will be read first by screen readers when the modal
   * becomes visible.
   */
  "aria-labelledby"?: string;
  /**
   * Children can either be one or more React nodes, or a function that returns
   * React nodes. If a function is provided, the single argument is an object
   * containing the property "dismiss" -- a function that can be called to
   * dismiss the modal. E.g.:
   *
   *   <InformationalModal ...>
   *     {({ dismiss }) => <button onClick={dismiss} />}
   *   <InformationalModal>
   */
  children?:
    | React.ReactNode
    | ((args: { dismiss: () => void }) => React.ReactNode);
  /**
   * Useful for disabling swiping when modal contains scrollable elements.
   */
  disableSwipeToClose?: boolean;
  /**
   * When viewed on mobile, occupy the entire viewport. Default: true.
   */
  mobileFullscreen?: boolean;
  /**
   * Callback fired when the modal has dismissed and is no longer visible.
   */
  onDismissed?: () => void;
  /**
   * React ref that points to the DOM element that should have focus returned
   * when the modal is closed. Almost always this should be the button that
   * was clicked that resulted in the modal appearing. This is required in
   * order to maintain good accessibility standards, where a screenreader
   * will need to understand to where the text cursor should be returned after
   * the modal is dismissed.
   */
  returnFocusRef?: React.RefObject<HTMLElement>;
  /**
   * Allows a secondary action element to be placed into the modal header, to
   * the left of the title. For example, the addition of a back button for multi-screen modals.
   */
  secondaryAction?: React.ReactNode;
  /**
   * The title that is displayed at the top of the modal and that labels the
   * modal for ARIA compliance. If omitted, the `aria-labelledby` property
   * must be supplied instead.
   */
  title?: React.ReactNode;
  /**
   * Set a custom width for the modal on desktop. On mobile devices, the modal
   * will grow to fit the screen size.
   */
  width?: number;
};

/**
 * Modal renders a generic modal interface. This component should be used
 * as a basis for defining other shared modal components for specific uses,
 * such as InformationalModal. Applications should almost always prefer the
 * use of the derivative, use-case–specific modal components, rather than
 * using Modal directly. If you're using this component, consult with your
 * designer to ensure that there isn't a way to use a standardized pattern
 * instead of creating a custom implementation.
 *
 * For general modal usage, see the sibling index module's documentation.
 */
export const Modal: React.FC<ModalProps> = ({
  "aria-labelledby": ariaLabelledBy,
  children,
  disableSwipeToClose = false,
  mobileFullscreen = true,
  onDismissed = noop,
  returnFocusRef,
  secondaryAction,
  title,
  width = 560,
}) => {
  if (!title && !ariaLabelledBy) {
    throw new Error(
      "Modal must be supplied either a title or an aria-labelledby prop",
    );
  }

  /**
   * Assigns a unique ID to this instance of the Modal component, which is used
   * to signal to the ModalContext when the modal appears or is dismissed.
   */
  const id = useMemo(() => Math.random().toString(36).substring(7), []);

  /**
   * Creates a unique DOM ID to be used to label the modal with the provided
   * title, when supplied.
   */
  const titleId = useId();

  const { register, unregister, isPrimary, modalIndexOf, __testing } =
    useModalContext();
  const modalRenderContext = useModalRenderContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [swipeOffset, setSwipeOffset] = useState(0);
  const [resettingSwipe, setResettingSwipe] = useState(false);
  const { width: windowWidth } = useWindowSize();

  /**
   * isVisible is set to null by default in order to signal a pre-visible state
   * which is toggled in the useEffect below after the next tick.
   * Unfortunately, this means that modal tests need to have a timeout to wait
   * for that next tick before interacting with the rendered content. To avoid
   * this, testing mode sets visibility to true immediately.
   */
  const [isVisible, setIsVisible] = useState<boolean | null>(__testing || null);

  const transitionDuration =
    windowWidth && windowWidth > MOBILE_BREAKPOINT
      ? TRANSITION_DURATION_OPACITY
      : TRANSITION_DURATION_TRANSFORM;

  const zIndex = modalIndexOf(id);

  useEffect(() => {
    /**
     * Allow the component to be mounted and then set to be visible on the next
     * tick, in order to allow the transitions to be applied after the initial
     * render has set the default styles.
     */
    const t = setTimeout(() => {
      setIsVisible(true);
    }, 1);

    /**
     * Record the container ref value here, as the reference to the element is
     * otherwise not available when the cleanup function of this useEffect is run.
     */
    const scrollLockTarget = containerRef.current;

    if (scrollLockTarget) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
      disableBodyScroll(scrollLockTarget as any, {
        /**
         * This option allows finer control over which elements can contine
         * to scroll when `disableBodyScroll` is invoked. In this case, any
         * element that is a child of the modal container should be allowed
         * to scroll on touch devices.
         */
        allowTouchMove: (el) => !!scrollLockTarget?.contains(el),
      });
    }
    /**
     * Signal to the ModalContext that a new modal has been mounted.
     */
    register(id);

    return () => {
      clearTimeout(t);

      if (scrollLockTarget) {
        enableBodyScroll(scrollLockTarget);
      }
      /**
       * Unregister here, even though this is also called in the next
       * useEffect, mostly to cover hot-reloading and Modal components being
       * unexpectedly yanked out of the tree without being dismissed. Calling
       * `unregister` redundantly has no effect, so this is safe.
       */
      unregister(id);
    };
  }, [id, register, unregister]);

  useEffect(() => {
    /**
     * Check for isVisible to be explicitly false here, as the initial state of
     * `null` is used as the component is mounting to signal a pre-visible state.
     */
    if (isVisible === false) {
      /**
       * Unregister the modal before the transition begins, in order to allow
       * the background overlay to transition out at the same time as the modal.
       */
      unregister(id);

      const t = setTimeout(() => {
        onDismissed();
      }, transitionDuration);

      return () => clearTimeout(t);
    }
    /**
     * TODO: The `onDismissed` callback should be added here as well, but adding it
     * causes infinite recursion. Some investigation is needed to figure how to
     * safely keep this prop up to date in the useEffect callback, but in common
     * use-cases it should be okay to omit for now.
     */
  }, [id, unregister, isVisible, transitionDuration]);

  const swipeHandlers = useSwipeable(
    disableSwipeToClose
      ? {}
      : {
          onSwipedDown: (event) => {
            if (
              !resettingSwipe &&
              event.deltaY > SWIPE_TOLERANCE &&
              /**
               * Ensure that there is not any scrollable content before dismissing
               * the modal. Only the modalRef needs to be checked, as this is the
               * only scrollable container on the mobile breakpoint.
               */
              modalRef?.current?.scrollTop &&
              modalRef.current.scrollTop === 0
            ) {
              setIsVisible(false);
            } else {
              setResettingSwipe(true);
              setSwipeOffset(0);

              setTimeout(() => {
                setResettingSwipe(false);
              }, SWIPE_RESET_DURATION);
            }
          },
          onSwiping: (event) => {
            if (!resettingSwipe) {
              setSwipeOffset(Math.max(0, event.deltaY));
            }
          },
        },
  );

  const dismiss = () => {
    setIsVisible(false);
  };

  // @ts-ignore (fix me please, do not replicate)
  const handleBackgroundClose = (e) => {
    /**
     * Calling stopPropagation shouldn't normally be required, but for some
     * reason if the modal component is mounted inside of a next.js Link
     * element this link still gets "clicked" when clicking on any anchor
     * or button element inside of the modal -- even though the modal is
     * rendered using a React portal. Stopping event bubbling here prevents
     * the next.js Link from being triggered.
     */
    e.stopPropagation();

    if (
      (e.type === "click" && e.target === containerRef.current) ||
      e.code === "Escape"
    ) {
      e.preventDefault();
      dismiss();
    }
  };

  const contentTransform = `translateY(${swipeOffset}px) ${
    !isPrimary(id) ? "scale(0.97)" : ""
  }`;

  return (
    modalRenderContext?.renderModal(
      <FocusLock
        disabled={!isPrimary(id)}
        onDeactivation={() => {
          if (returnFocusRef && returnFocusRef.current) {
            returnFocusRef.current.focus();
          }
        }}
      >
        <div
          className="fixed bottom-0 left-0 right-0 top-0 cursor-default outline-none md:overflow-y-scroll"
          onClick={handleBackgroundClose}
          onKeyDown={handleBackgroundClose}
          ref={containerRef}
          role="presentation"
          style={{ zIndex }}
        >
          <Transition
            as={React.Fragment} // Rendering as a Fragment makes Transition apply these classes to the child element
            enterFrom="translate-y-[100%] md:transform-none md:opacity-0 motion-reduce:transform-none motion-reduce:opacity-0"
            enterTo="translate-y-[0%] md:transform-none md:opacity-1 motion-reduce:transform-none motion-reduce:opacity-1"
            leaveFrom="translate-y-[0%] md:transform-none md:opacity-1 motion-reduce:transform-none motion-reduce:opacity-1"
            leaveTo="translate-y-[100%] md:transform-none md:opacity-0 motion-reduce:transform-none motion-reduce:opacity-0"
            show={!!isVisible}
          >
            <div
              aria-hidden={!isPrimary(id)}
              aria-labelledby={title ? titleId : ariaLabelledBy}
              aria-modal={isPrimary(id)}
              className={classNames(
                "modal",
                "transition-transform md:transition-opacity motion-reduce:transition-opacity",
                "fixed bottom-0 left-0 right-0 md:relative md:top-[15vh] md:bottom-auto",
                "w-auto max-h-full md:max-h-none md:h-auto md:min-h-auto",
                "focus:outline-none cursor-default text-default",
                "m-auto md:pb-[5vh]",
                "overflow-y-auto md:overflow-y-unset",
                {
                  "top-0 md:top-[15vh]": mobileFullscreen,
                },
              )}
              data-testid="modal"
              ref={modalRef}
              role="dialog"
              style={{ transitionDuration: `${transitionDuration}ms` }}
            >
              <section
                className={classNames(
                  "h-full modal-content md:rounded bg-white shadow-low pb-lg px-lg flex flex-col pt-[40px] min-h-full w-full overflow-y-auto",
                  {
                    "blur-[0.5px]": !isPrimary(id),
                    "modal-content--resetting": resettingSwipe,
                    "pt-[64px] supports-[position:sticky]:pt-0": Boolean(title),
                  },
                )}
                style={{ transform: contentTransform }}
                /**
                 * These handlers need to be installed on the child element of
                 * `.modal`, as they add a ref that interferes with the ref
                 * installed on the parent.
                 */
                {...swipeHandlers}
              >
                <div
                  className={classNames(
                    "absolute top-0 left-0 right-0 bg-white z-[60] md:rounded-t flex",
                    {
                      [`border-b border-light-grey
                        supports-[position:sticky]:sticky
                        supports-[position:sticky]:m-[0_-16px_16px]
                        supports-[position:sticky]:left-[unset]
                        supports-[position:sticky]:right-[unset]`]:
                        Boolean(title),
                    },
                  )}
                >
                  {secondaryAction && secondaryAction}
                  {title && (
                    <h1
                      id={titleId}
                      className="title py-[14px] px-lg text-xs uppercase font-extrabold w-full"
                    >
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap pr-[30px]">
                        {title}
                      </div>
                    </h1>
                  )}

                  <button
                    aria-label="Close modal"
                    className="close-button top-[14px] right-[14px] absolute"
                    onClick={dismiss}
                    data-testid="close-modal"
                  >
                    <CloseIcon width={22} height={22} />
                  </button>
                </div>

                {/**
                 * Adding "grow" to this element allows it to take up the entire
                 * space allocated for the modal content when the modal occupies
                 * the entire screen on smaller device sizes. This in turn allows
                 * content to use the `h-full` utility class to set the elements
                 * size relative to this parent; useful for embedded content like
                 * Typeform that might want as much space as allowed.
                 */}
                <div className="grow">
                  {children instanceof Function
                    ? children({ dismiss })
                    : children}
                </div>

                {/**
                 * This element acts as an visual mask to any "background" modals
                 * when multiple modals are displayed. The non-primary modal(s) are
                 * blurred and darkened to retain visual focus on the foreground.
                 */}
                <div
                  className={classNames(
                    "absolute top-0 left-0 h-full w-full bg-black opacity-0 pointer-events-none z-[61]",
                    {
                      "opacity-50": !isPrimary(id),
                    },
                  )}
                  style={{
                    transition: `opacity ${TRANSITION_DURATION_TRANSFORM}ms ease-in-out`,
                  }}
                ></div>
              </section>
            </div>
          </Transition>
        </div>
        <style jsx>{`
          @media screen and (min-width: 768px) {
            .modal {
              width: ${width}px;
            }
          }

          .modal-content {
            transition:
              transform ${TRANSITION_DURATION_TRANSFORM}ms ease-in-out,
              filter ${TRANSITION_DURATION_TRANSFORM}ms ease-in-out;
          }

          .modal-content--resetting {
            transition: transform ${SWIPE_RESET_DURATION}ms ease-in;
          }
        `}</style>
      </FocusLock>,
    ) || <></>
  );
};
