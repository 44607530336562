"use client";

import Toploader from "nextjs-toploader";

/**
 * This component wraps "nextjs-toploader" to add the "use client" declaration.
 */
export const PageTransitionProgressBar = () => (
  <Toploader
    color="var(--color-green)"
    initialPosition={0.3}
    crawlSpeed={25}
    showSpinner={false}
    shadow="0"
  />
);
