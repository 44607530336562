import { type AxiosRequestHeaders } from "axios";

import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";

import { userNotificationErrorInterceptor } from "./user-notifications";

// Initialize the service request with the app and environment
const userNotificationCaApi = serviceRequest();

// Configure and set upstream URL
userNotificationCaApi.defaults.baseURL =
  publicConfig.services.userNotificationCaApi.url;
userNotificationCaApi.defaults.withCredentials = true;

userNotificationCaApi.interceptors.response.use(
  (response) => response,
  userNotificationErrorInterceptor,
);

const headers = userNotificationCaApi.defaults
  .headers as unknown as AxiosRequestHeaders;

// notificaitons api does not like these
delete headers["X-App"];
delete headers["X-Environment"];

export default userNotificationCaApi;
