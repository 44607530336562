"use client";

import React from "react";
import dynamic from "next/dynamic";

import { useWeedfetti } from "./useWeedfetti";

const WeedfettiParticles = dynamic(() => import("./WeedfettiParticles"));

export function WeedfettiCannon() {
  const { instances, cleanup } = useWeedfetti();

  return (
    <>
      {instances.map(({ id, colors }) => (
        <WeedfettiParticles
          key={id}
          colors={colors}
          onConfettiComplete={() => cleanup(id)}
        />
      ))}
    </>
  );
}
