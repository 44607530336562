import userNotificationApi from "api/services/user-notifications";
import userNotificationCaApi from "api/services/user-notifications-ca";
import logError from "utils/logError";

const getUserNotificationCounts = async ({
  countryCode,
}: {
  countryCode: string;
}) => {
  const api =
    countryCode === "CA" ? userNotificationCaApi : userNotificationApi;
  try {
    const { data } = await api.get<{ unread: number }>(
      "/v2/user/notification_counts",
    );

    return data;
  } catch (error) {
    if (error.statusCode !== 401) {
      logError(error.message, {
        functionName: "getUserNotificationCounts",
        service: "usernotification",
        statusCode: error.statusCode,
      });
    }

    return null;
  }
};

export default getUserNotificationCounts;
