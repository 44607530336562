/* eslint-disable @typescript-eslint/no-var-requires -- some imports must use `require`; comments inline below */

import memoize from "lodash/memoize";

import type { CountryCode } from "custom-types/CountryCode";
import { getDomainCountryCode } from "redux/selectors/config";
import { getCountryCodeHeader } from "utils/requestContext";

const DOMAIN_COUNTRY_CODE_META_SELECTOR = "meta[name='x-country-code']";

const USING_PAGES_ROUTER_RUNTIME =
  /* eslint-disable-next-line n/no-process-env -- this private API appears to
      be the only way to detect if we're executing within the pages router
      on the server. This can be removed once we've fully migrated the domain
      country code away from Redux; we're taking on the risk that this private
      interface doesn't change before we're able to do that.

      See: https://github.com/vercel/next.js/blob/7bf425d5326e44d5506b827b6366eb23a590510a/packages/next/src/server/require.ts#L123 */
  process.env.__NEXT_PRIVATE_RUNTIME_TYPE === "pages";

let useDomainCountryCodeHeader: () => string | undefined | null;

if (
  typeof window !== "undefined" &&
  document.querySelector(DOMAIN_COUNTRY_CODE_META_SELECTOR)
) {
  /**
   * For client-side / App Router
   *
   * In the App Router, we have to find a way to communicate headers that are
   * only available server-side to client-side code. This code checks for a
   * specific meta tag on the page that should be generated in either the
   * custom document (Pages Router) or root layout (App Router), both of which
   * are only rendered on the server:
   *
   *   <meta name="x-country-code" content="CA">
   */
  useDomainCountryCodeHeader = memoize(
    () =>
      document.querySelector<HTMLMetaElement>(DOMAIN_COUNTRY_CODE_META_SELECTOR)
        ?.content,
  );
} else if (typeof window !== "undefined" || USING_PAGES_ROUTER_RUNTIME) {
  /**
   * For client- and server-side / Pages Router
   *
   * This function encapsulates date that is currently stored in redux but should
   * be migrated to a different mechanism. The domain country code does not change
   * over the course of the page lifecycle, so does not need to be stored in redux.
   *
   * For now, this implementation exists for backwards compatability with the Pages
   * Router implementation, until the point that it's possible to remove this data
   * from redux entirely.
   *
   * `react-redux` must be imported using `require` inside of this conditional, as
   * `React.createContext` is called during import and is not supported in the runtime
   * used to get static props in the App Router.
   */
  const redux = require("react-redux") as typeof import("react-redux");

  useDomainCountryCodeHeader = () => redux.useSelector(getDomainCountryCode);
} else if (typeof window === "undefined") {
  /**
   * For server-side App Router
   *
   * This implementation only works in environments that support `next/headers`, which
   * is only the App Router at the time of writing.
   *
   * `require` must be used here in order to import this module in a way that will
   * avoid adding this module to the client-side bundle (where it is not permitted).
   * The check for `typeof window === "undefined"` allows the compiler to omit this
   * conditional branch entirely before checking for invalid imports.
   */
  const { headers } = require("next/headers") as typeof import("next/headers");

  useDomainCountryCodeHeader = () =>
    getCountryCodeHeader(Object.fromEntries(headers().entries()));
} else {
  // This error should not be reachable
  throw new Error("useDomainCountryCode was used in an invalid way");
}

export default (): CountryCode => {
  const code = useDomainCountryCodeHeader();

  if (code === "US" || code === "CA") {
    return code;
  } else {
    return "US";
  }
};
