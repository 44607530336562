import { useEffect } from "react";
import { useClientSideSplitTreatments } from "@leafly-com/split-next";
import { useDispatch, useStore } from "react-redux";

import { getSplitKey } from "utils/split/getSplitKey";

import { getUserLocationData } from "../api/requests/getUserLocation";
import { updateLocation } from "../redux/action-creators/location";
import { RootState } from "../redux/reducers/rootReducer";
import {
  getCity,
  getCountryCode,
  getHasLoadedLocationCookie,
  getPostalCode,
  getState,
} from "../redux/selectors/location";
import useDomainCountryCode from "./useDomainCountryCode";

export const useGoogleAnalyticsUserLocation = () => {
  const countryCode = useDomainCountryCode();
  const store = useStore<RootState>();
  const dispatch = useDispatch();

  const { treatments, isLoading } = useClientSideSplitTreatments(
    "webWeb_gaGeolocation_frontend",
    getSplitKey(),
  );
  const setAnalyticsLocation =
    !isLoading && treatments.webWeb_gaGeolocation_frontend.treatment === "on";

  async function setGoogleAnalyticsLocation() {
    if (typeof window === "undefined") {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    const hasLoadedLocationFromCookie = getHasLoadedLocationCookie(
      store.getState(),
    );
    if (!hasLoadedLocationFromCookie) {
      const location = await getUserLocationData(countryCode);
      if (!location) {
        return;
      }

      updateLocation(dispatch, location);
    }

    const country = getCountryCode(store.getState());
    const countriesAllowingLocationCapture = ["US", "CA"];
    if (countriesAllowingLocationCapture.includes(country)) {
      window.dataLayer.push({
        location: {
          city: getCity(store.getState()),
          country,
          postalCode: getPostalCode(store.getState()),
          region: getState(store.getState()),
        },
      });
    }
  }

  useEffect(() => {
    if (setAnalyticsLocation) {
      setGoogleAnalyticsLocation();
    }
  }, [setAnalyticsLocation]);
};
