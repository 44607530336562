import publicConfig from "config/public";
import logError from "utils/logError";

import isImageSrcPreSigned from "./isImageSrcPreSigned";

// There are a lot of source urls in imgix, most of which have imgix.net in the hostname
const IMGIX_HOSTNAME_PARTIALS = [
  "directus-media",
  "imgix.net",
  "images-integration.leafly.io",
  "images.leafly.com",
];

const {
  imgix: { publicUrl, imagesUrl, cmsIntegrationUrl, cmsProductionUrl },
} = publicConfig;

const formatImgixUrl = (url: string): string => {
  try {
    const { hostname, pathname, origin } = new URL(url);

    if (isImageSrcPreSigned(url)) {
      return url;
    }

    const isAlreadyImgixUrl = IMGIX_HOSTNAME_PARTIALS.some((partial) =>
      hostname.includes(partial),
    );
    if (isAlreadyImgixUrl) {
      // Since we apply our own parameters on the frontend, create a url
      // based on the original with no parameters
      return `${origin}${pathname}`;
    }

    // There is nothing in here to convert a directus url because directus
    // only returns a pathname. In all directus API functions where images
    // are returned, devs should use createDirectusImgixUrl to create the url

    const isImagePublic =
      hostname.includes("leafly-public.s3") ||
      hostname.includes("leafly-public-integration.s3") ||
      pathname.includes("/leafly-public");
    if (isImagePublic) {
      return `${publicUrl}${pathname.replace("/leafly-public", "")}`;
    }

    const isImageImages =
      hostname.includes("leafly-images.s3") ||
      pathname.includes("/leafly-images");
    if (isImageImages) {
      return `${imagesUrl}${pathname.replace("/leafly-images", "")}`;
    }

    const isImageCmsProduction = hostname.includes("leafly-cms-production.s3");
    if (isImageCmsProduction) {
      return `${cmsProductionUrl}${pathname}`;
    }

    const isImageCmsIntegration = hostname.includes("leafly-cms-staging.s3");
    if (isImageCmsIntegration) {
      return `${cmsIntegrationUrl}${pathname}`;
    }

    return url;
  } catch {
    if (url) {
      logError("Invalid image url " + JSON.stringify(url));
    }

    return url;
  }
};

export default formatImgixUrl;
