export function setSessionStorage(key: string, value: string) {
  if (key && value && storageAvailable("sessionStorage")) {
    window.sessionStorage.setItem(key, value);
  }
}

export function getSessionStorage(key: string) {
  if (key && storageAvailable("sessionStorage")) {
    return window.sessionStorage.getItem(key);
  }
}

export function clearSessionStorage(key: string) {
  if (key && storageAvailable("sessionStorage")) {
    window.sessionStorage.removeItem(key);
  }
}

function storageAvailable(type: string) {
  if (typeof window === "undefined") {
    return false;
  }

  let storage;
  try {
    storage = window[type as keyof Window];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    if (e instanceof DOMException) {
      return (
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      );
    }

    return false;
  }
}
