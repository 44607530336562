"use client";

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from "react";

export const BASE_Z_INDEX = 140;

type ModalContextProps = {
  __testing: boolean;
  count: number;
  isPrimary: (symbol: string) => boolean;
  modalIndexOf: (symbol: string) => number;
  register: (symbol: string) => void;
  unregister: (symbol: string) => void;
};

const ModalContext = createContext<ModalContextProps | null>(null);

ModalContext.displayName = "ModalContext";

export const ModalConsumer = ModalContext.Consumer;

type StateType = {
  visible: Set<unknown>;
  index: number;
  indices: Record<string, number>;
};

type ActionType = {
  value: string;
  type: string;
};

const visibleReducer = (state: StateType, action: ActionType) => {
  const { visible, index, indices } = state;

  switch (action.type) {
    case "register":
      /**
       * When a modal is registered, the provided action value (a unique ID)
       * is recorded in an array to track the visible modal(s), and an index
       * is incremented to ensure that the modal(s) visually stack in the order
       * that they are addeded to the DOM.
       */
      return {
        index: index + 1,
        indices: { [action.value]: index, ...indices },
        visible: new Set(visible).add(action.value),
      };
    case "unregister": {
      /**
       * When unregistering a modal, it is removed from the visible set, but
       * is retained in the list of indices unless the modal is the last to
       * be dismissed. This allows the stacking order of modals to be
       * maintained while the topmost dismissed modal is transitioning out.
       */
      const next = new Set(visible);
      next.delete(action.value);
      const reset = next.size === 0;

      return {
        index: reset ? 0 : index,
        indices: reset ? {} : indices,
        visible: next,
      };
    }
    default:
      throw new Error(`invalid action: ${action.type}`);
  }
};

type ModalProviderType = {
  children: ReactNode;
  __testing?: boolean;
};

export function ModalProvider({
  children,
  __testing = false,
}: ModalProviderType) {
  const [{ visible, indices }, dispatch] = useReducer(visibleReducer, {
    index: 0,
    indices: {},
    visible: new Set(),
  });

  /**
   * The register and unregister functions must be called when mounting and
   * unmounting a modal, respectively.
   */
  const register = useCallback(
    (id: string) => {
      dispatch({ type: "register", value: id });
    },
    [dispatch],
  );

  const unregister = useCallback(
    (id: string) => {
      dispatch({ type: "unregister", value: id });
    },
    [dispatch],
  );

  /**
   * The count of visible modals is used to disable body scroll and display
   * the tinted overlay over the inactive page contents.
   */
  const count = visible.size;

  /**
   * modalIndexOf and isPrimary are provided to allow modals to respond to
   * their position in the stacking order of modals, in the case multiple
   * modals are displayed at the same time (even if a rare occurance).
   */
  const modalIndexOf = (id: string) => BASE_Z_INDEX + (indices[id] || 0);
  const isPrimary = (id: string) =>
    // either: top of visible stack, or not in visible list (being dismissed)
    Array.from(visible)[visible.size - 1] === id || !visible.has(id);

  return (
    <ModalContext.Provider
      value={{
        __testing,
        count,
        isPrimary,
        modalIndexOf,
        register,
        unregister,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export class MissingModalContextError extends Error {
  constructor() {
    super(
      "ModalFrame must be rendered in the application to use this component",
    );
    Object.setPrototypeOf(this, MissingModalContextError.prototype);
  }
}

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new MissingModalContextError();
  }

  return context;
};
