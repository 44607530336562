import { stringify } from "qs";

import publicConfig from "config/public";
import { Query } from "custom-types/Query";

const {
  services: { sso, ssoCa },
  leaflyDomain,
  leaflyCaDomain,
} = publicConfig;

const generateUrl = (
  countryCode = "US",
  ssoPath?: string,
  redirect?: string,
  params?: Query,
) => {
  const redirectDomain = countryCode === "CA" ? leaflyCaDomain : leaflyDomain;
  const url = countryCode === "CA" ? ssoCa.url : sso.url;

  const queryparams = {
    ...params,
    ...(redirect ? { rd: redirectDomain + redirect } : {}),
  };

  const queryParamsString = Object.keys(queryparams).length
    ? `?${stringify(queryparams)}`
    : "";

  return `${url}${ssoPath || ""}${queryParamsString}`;
};

export const generateSignUpUrl = (
  countryCode: string,
  redirect?: string,
  params?: Query,
) => generateUrl(countryCode, "/sign-up", redirect, params);

export const generateSignInUrl = (
  countryCode: string,
  redirect?: string,
  params?: Query,
) => generateUrl(countryCode, "/sign-in", redirect, params);

export const generateSignOutUrl = (
  countryCode: string,
  redirect?: string,
  params?: Query,
) => generateUrl(countryCode, "/sign-out", redirect, params);

export const generateBaseUrl = (
  countryCode: string,
  redirect?: string,
  params?: Query,
) => generateUrl(countryCode, undefined, redirect, params);
