interface Text {
  [key: string]: string;
}

interface L10nStrings {
  [key: string]: Text;
}

const l10nStrings: L10nStrings = {
  ca: {
    Dispensaries: "Stores",
    Dispensary: "Store",
    Order: "Reservation",
    State: "Province",
    States: "Provinces",
    dispensaries: "stores",
    dispensary: "store",
    order: "reservation",
    state: "province",
    states: "provinces",
  },
};

export const localizeText = (text: string, countryCode: string) => {
  let strings: Text = {};

  if (countryCode) {
    strings = l10nStrings[countryCode.toLowerCase()];
  }

  return strings?.[text] ? strings[text] : text;
};
