import React from "react";
import Link from "next/link";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

import LogoIcon from "components/Icons/logo.svg";

export const Logo = () => {
  const { publishEvent } = useEventTracker();

  return (
    <Link
      data-testid="logo"
      aria-label="Leafly"
      className="text-green flex-shrink-0 header__logo"
      href="/"
      onClick={() =>
        publishEvent({
          action: Action.click,
          category: Category.header,
          label: "leafly_logo_home",
        })
      }
    >
      <LogoIcon width="70" height="24" />
    </Link>
  );
};
