// A note on leafly.userid cookie. This ain't gonna work locally unless you have https
// setup in some way. Since the leafly.userid is set by the sso using 'secure' and samesite: none'
// parameters, the js-cookie library won't be able to read that cookie. Removing those items from
// the cookie will enable the cookie to be visible to the js-cookie library.

import { Dispensary } from "custom-types/Dispensary";
import { Strain } from "custom-types/Strain";
import { Preferences } from "custom-types/StrainPreferences";
import {
  CardImageDataResponse,
  FulfillmentPreference,
  MedCard,
  User,
  UserReviewsApiResponse,
  UserState,
} from "custom-types/User";
import { LOAD_COOKIES, LoadCookiesAction } from "redux/action-creators/cookies";
import { HYDRATE, HydrateAction } from "redux/reducers/hydration";
import { MENU_TITLES, menuTypeCookieName } from "utils/menuTypeUtils";

type CardType = "government" | "medical";

export const initialState: UserState = {
  fulfillmentPreference: undefined,
  governmentCardImageData: undefined,
  isLoggedOutRedirect: false,
  loading: true,
  medicalCardImageData: undefined,
  prefersMedical: undefined,
};

export const USER_LOAD = "user/profile/load";
export const USER_PREFERENCE_CHANGE = "user/preference/change";
export const USER_FAVORITES_LOAD = "user/favorites/load";
export const USER_FAVORITES_DISPENSARIES_LOAD =
  "user/favorites/dispensaries/load";
export const USER_FAVORITED_STRAINS_RESULTS = "user/favorites/results";
export const USER_FAVORITED_STRAINS_MORE_RESULTS =
  "user/favorites/more-results";
export const USER_STRAIN_PREFERENCES_LOAD = "user/preferences/strains/load";
export const USER_STRAIN_PREFERENCES_RESULTS =
  "user/preferences/strains/results";
export const USER_FOLLOWED_DISPENSARIES_RESULTS = "user/followed/dispensaries";
export const USER_REVIEWS_LOAD = "user/reviews/load";
export const USER_REVIEWS_RESULTS = "user/reviews/results";
export const USER_FULFILLMENT_PREFERENCE_CHANGE =
  "user/fulfillmentPreference/change";
export const USER_LOGGED_OUT_REDIRECT = "USER_LOGGED_OUT_REDIRECT";
export const USER_SAVE_ID_REQUEST = "USER_SAVE_ID_REQUEST";
export const USER_SAVE_ID_SUCCESS = "USER_SAVE_ID_SUCCESS";
export const USER_SAVE_ID_FAIL = "USER_SAVE_ID_FAIL";
export const USER_LOAD_MED_CARD = "USER_LOAD_MED_CARD";
export const USER_LOAD_ID_CARDS_REQUEST = "USER_LOAD_ID_CARDS_REQUEST";
export const USER_LOAD_ID_CARDS_SUCCESS = "USER_LOAD_ID_CARDS_SUCCESS";
export const USER_LOAD_ID_CARDS_FAILURE = "USER_LOAD_ID_CARDS_FAILURE";

type ChangePrefersMedicalAction = {
  type: typeof USER_PREFERENCE_CHANGE;
  prefersMedical: boolean;
};

export type UserFulfillmentPreferenceChange = {
  type: typeof USER_FULFILLMENT_PREFERENCE_CHANGE;
  fulfillmentPreference: FulfillmentPreference;
};

export type UserLoadAction = {
  type: typeof USER_LOAD;
  userData: User;
};

export type UserFavoritesLoadAction = {
  type: typeof USER_FAVORITES_LOAD;
};

export type UserFavoritesDispensariesLoadAction = {
  type: typeof USER_FAVORITES_DISPENSARIES_LOAD;
};

export type UserFavoritesResultsAction = {
  type: typeof USER_FAVORITED_STRAINS_RESULTS;
  favoritedStrains: { strains: Strain[]; totalCount: number };
};

export type UserFavoritesMoreResultsAction = {
  type: typeof USER_FAVORITED_STRAINS_MORE_RESULTS;
  favoritedStrains: { strains: Strain[]; totalCount: number };
};

export type UserStrainPreferencesLoadAction = {
  type: typeof USER_STRAIN_PREFERENCES_LOAD;
};

export type UserStrainPreferencesResultsAction = {
  type: typeof USER_STRAIN_PREFERENCES_RESULTS;
  preferences: Preferences[];
  strainSlug?: string;
  strainMatchQuery?: string;
};

export type UserFollowedDispensariesAction = {
  type: typeof USER_FOLLOWED_DISPENSARIES_RESULTS;
  followedDispensaries: { dispensaries: Dispensary[]; totalCount: number };
};

export type UserReviewsLoadAction = {
  type: typeof USER_REVIEWS_LOAD;
};

export type UserReviewsResultsAction = {
  type: typeof USER_REVIEWS_RESULTS;
  userReviewsData: UserReviewsApiResponse;
};

export type UserLoggedOutRedirectAction = {
  type: typeof USER_LOGGED_OUT_REDIRECT;
  isLoggedOutRedirect: boolean;
};

export type UserSaveIdRequestAction = {
  cardType: CardType;
  type: typeof USER_SAVE_ID_REQUEST;
};

export type UserSaveIdSuccessAction = {
  type: typeof USER_SAVE_ID_SUCCESS;
  cardType: "government" | "medical";
  payload: {
    createdAt: Date;
  };
};

export type UserSaveIdFailAction = {
  type: typeof USER_SAVE_ID_FAIL;
  cardType: "government" | "medical";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  error: any;
};

export type UserLoadMedCardAction = {
  type: typeof USER_LOAD_MED_CARD;
  medCard: MedCard;
};

export type UserLoadIdCardRequestAction = {
  type: typeof USER_LOAD_ID_CARDS_REQUEST;
};

export type UserLoadIdCardSuccessAction = {
  type: typeof USER_LOAD_ID_CARDS_SUCCESS;
  cardData: CardImageDataResponse;
};

export type UserLoadIdCardFailureAction = {
  type: typeof USER_LOAD_ID_CARDS_FAILURE;
};

export type UserActions =
  | ChangePrefersMedicalAction
  | LoadCookiesAction
  | UserFavoritesLoadAction
  | UserFavoritesDispensariesLoadAction
  | UserFavoritesMoreResultsAction
  | UserFavoritesResultsAction
  | UserStrainPreferencesLoadAction
  | UserStrainPreferencesResultsAction
  | UserFollowedDispensariesAction
  | UserLoadAction
  | UserReviewsLoadAction
  | UserReviewsResultsAction
  | UserFulfillmentPreferenceChange
  | UserLoggedOutRedirectAction
  | UserSaveIdRequestAction
  | UserSaveIdSuccessAction
  | UserSaveIdFailAction
  | UserLoadMedCardAction
  | UserLoadIdCardRequestAction
  | UserLoadIdCardSuccessAction
  | UserLoadIdCardFailureAction
  | HydrateAction;

export const parseUserId = (
  cookieValue: string | undefined | null,
): number | undefined => {
  if (cookieValue) {
    const id = parseInt(cookieValue);
    if (!isNaN(id)) {
      return id;
    }
  }
  return undefined;
};

const userReducer = (state = initialState, action: UserActions): UserState => {
  switch (action.type) {
    case HYDRATE:
      /**
       * Ignore any hydration events after initial page load to preserve any
       * subsequent changes that have been made on the client.
       */
      return state;
    case LOAD_COOKIES:
      return {
        ...state,
        fulfillmentPreference:
          // @ts-ignore (fix me please, do not replicate)
          FulfillmentPreference[
            action?.cookies["userFulfillmentPreference"]?.toUpperCase()
          ] || FulfillmentPreference.DELIVERY,
        id:
          state.id ||
          parseUserId(action.cookies && action.cookies["leafly.userid"]),
        loading: false,
        prefersMedical:
          typeof state.prefersMedical === "boolean"
            ? state.prefersMedical
            : action.cookies &&
              (action.cookies[menuTypeCookieName] || "").toLowerCase() ===
                MENU_TITLES.med.shortLower,
      };
    case USER_LOAD:
      return {
        ...state,
        ...action.userData,
      };
    case USER_FAVORITES_LOAD:
      return {
        ...state,
        favoritedStrains: {
          ...state.favoritedStrains,
          loading: true,
        },
        followedDispensaries: {
          ...state.followedDispensaries,
          loading: true,
        },
      };
    case USER_FAVORITED_STRAINS_RESULTS:
      return {
        ...state,
        favoritedStrains: {
          loading: false,
          strains: action.favoritedStrains.strains,
          totalCount: action?.favoritedStrains?.totalCount,
        },
      };
    case USER_FAVORITED_STRAINS_MORE_RESULTS:
      return {
        ...state,
        favoritedStrains: {
          loading: false,
          strains: [
            ...(state?.favoritedStrains?.strains || []),
            ...action.favoritedStrains.strains,
          ],
          totalCount: state?.favoritedStrains?.totalCount,
        },
      };
    case USER_STRAIN_PREFERENCES_LOAD:
      return {
        ...state,
        strainPreferences: {
          ...state.strainPreferences,
          loading: true,
        },
      };
    case USER_STRAIN_PREFERENCES_RESULTS:
      return {
        ...state,
        strainPreferences: {
          loading: false,
          preferences: action.preferences,
          strainMatchQuery: action.strainMatchQuery,
          strainSlug: action.strainSlug,
        },
      };
    case USER_FOLLOWED_DISPENSARIES_RESULTS:
      return {
        ...state,
        followedDispensaries: {
          dispensaries: [
            ...(state?.followedDispensaries?.dispensaries || []),
            ...action.followedDispensaries.dispensaries,
          ],
          loading: false,
          totalCount: action?.followedDispensaries?.totalCount,
        },
      };
    case USER_REVIEWS_LOAD:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: true,
        },
      };
    case USER_REVIEWS_RESULTS:
      return {
        ...state,
        reviews: {
          items: [
            ...(state?.reviews?.items || []),
            ...action.userReviewsData.data,
          ],
          loading: false,
          totalCount: action?.userReviewsData?.metadata?.totalCount || 0,
        },
      };
    case USER_PREFERENCE_CHANGE:
      return {
        ...state,
        prefersMedical: action.prefersMedical,
      };
    case USER_FULFILLMENT_PREFERENCE_CHANGE:
      return {
        ...state,
        fulfillmentPreference:
          // @ts-ignore (fix me please, do not replicate)
          FulfillmentPreference[action.fulfillmentPreference.toUpperCase()] ||
          FulfillmentPreference.DELIVERY,
      };
    case USER_LOGGED_OUT_REDIRECT:
      return {
        ...state,
        isLoggedOutRedirect: action.isLoggedOutRedirect,
      };
    case USER_SAVE_ID_REQUEST:
      return {
        ...state,
        [`${action.cardType}CardImageData`]: {
          ...state[`${action.cardType}CardImageData`],
          loading: true,
        },
      };
    case USER_SAVE_ID_SUCCESS:
      return {
        ...state,
        [`${action.cardType}CardImageData`]: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case USER_SAVE_ID_FAIL:
      return {
        ...state,
        [`${action.cardType}CardImageData`]: {
          ...state[`${action.cardType}CardImageData`],
          error: action.error,
          loading: false,
        },
      };
    case USER_LOAD_MED_CARD:
      return {
        ...state,
        medCard: action.medCard,
      };
    case USER_LOAD_ID_CARDS_REQUEST:
      return {
        ...state,
        governmentCardImageData: {
          ...state.governmentCardImageData,
          loading: true,
        },
        medicalCardImageData: {
          ...state.medicalCardImageData,
          loading: true,
        },
      };
    case USER_LOAD_ID_CARDS_SUCCESS:
      return {
        ...state,
        governmentCardImageData: {
          data: {
            createdAt: action.cardData.stateIdUpdatedAt,
            key: action.cardData.stateIdS3Key,
          },
          loading: false,
        },
        medicalCardImageData: {
          data: {
            createdAt: action.cardData.medicalIdUpdatedAt,
            key: action.cardData.medicalIdS3Key,
          },
          loading: false,
        },
      };
    case USER_LOAD_ID_CARDS_FAILURE:
      return {
        ...state,
        governmentCardImageData: {
          ...state.governmentCardImageData,
          loading: false,
        },
        medicalCardImageData: {
          ...state.medicalCardImageData,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
