import React from "react";

import { FooterLink } from "./FooterLink";
import { ResponsiveAccordion } from "./ResponsiveAccordion";

export const PrivacyAndTerms: React.FC<{ isResponsive?: boolean }> = ({
  isResponsive,
}) => {
  return (
    <ResponsiveAccordion
      label="Privacy &amp; Terms"
      defaultExpanded={false}
      isResponsive={isResponsive}
    >
      <ul className="text-sm text-green font-bold">
        <li className="py-sm mr-md">
          <FooterLink href="/info/terms-of-use" trackingLabel="terms of use">
            Terms of use
          </FooterLink>
        </li>
        <li className="py-sm mr-md">
          <FooterLink
            href="/info/commercial-terms-of-use"
            trackingLabel="commercial terms of use"
          >
            Commercial terms of use
          </FooterLink>
        </li>
        <li className="py-sm mr-md">
          <FooterLink
            href="/info/privacy-policy"
            trackingLabel="privacy policy"
          >
            Privacy policy
          </FooterLink>
        </li>
        <li className="py-sm mr-md">
          <FooterLink
            href="/info/privacy-policy#ccpa-do-not-sell"
            trackingLabel="do not sell my personal information"
          >
            Do not sell my personal information
          </FooterLink>
        </li>
      </ul>
    </ResponsiveAccordion>
  );
};
