import React, { useId } from "react";
import ContentLoader from "react-content-loader";

const Placeholder: React.FC<{
  children?: React.ReactNode;
  className?: string;
  height?: string;
  /**
   * SVG attribute used to preserve aspect ratio in a container given a viewbox
   */
  preserveAspectRatio?: string;
  /**
   * SVG attribute to determine viewbox parameters
   */
  viewBox?: string;
  /**
   * Width for your svg element. Can be fixed or percentage.
   */
  width?: string;
  style?: React.CSSProperties;
}> = ({ children, ...props }) => {
  const combinedProps = {
    backgroundColor: "#f3f3f3",
    foregroundColor: "#ecebeb",
    speed: 2,
    ...props,
  };
  const uniqueKey = useId();
  return (
    <ContentLoader {...combinedProps} uniqueKey={uniqueKey}>
      {children}
    </ContentLoader>
  );
};

export const Rect = ({ ...props }) => {
  const combinedProps = { rx: "3", ry: "3", x: "0", y: "0", ...props };
  return <rect {...combinedProps} />;
};

export default Placeholder;
