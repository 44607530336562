"use client";

import { useEffect } from "react";

import publicConfig from "config/public";
import { getSplitKey } from "utils/split/getSplitKey";

const SplitRumAgent = () => {
  useEffect(() => {
    const setupSplitRumAgent = async () => {
      if (!publicConfig.splitClientKey) {
        return;
      }

      try {
        const [splitKey, { SplitRumAgent, webVitals }] = await Promise.all([
          getSplitKey(),
          import("@splitsoftware/browser-rum-agent"),
        ]);

        await SplitRumAgent.setup(publicConfig.splitClientKey)
          .register(webVitals())
          .addIdentity({ key: splitKey, trafficType: "consumer" });
      } catch (err) {
        console.error("Error loading Agent", err);
      }
    };

    setupSplitRumAgent();
  }, []);

  return null;
};

export default SplitRumAgent;
