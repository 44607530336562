import publicConfig from "config/public";

const datadogInit = ({ env, version }: { env: string; version: string }) => {
  Promise.all([
    import("@datadog/browser-logs"),
    publicConfig.datadogRum.enabled
      ? import("@datadog/browser-rum-slim")
      : Promise.resolve({ datadogRum: { init: () => {} } }),
  ]).then(([{ datadogLogs }, { datadogRum }]) => {
    datadogLogs.init({
      clientToken: publicConfig.datadogClientToken,
      env,
      forwardErrorsToLogs: true,
      service: "web-web",
      sessionSampleRate: 100,
      site: "datadoghq.com",
      version,
    });
    datadogRum.init({
      applicationId: publicConfig.datadogRum.applicationId,
      clientToken: publicConfig.datadogRum.clientToken,
      env,
      service: "web-web",
      sessionReplaySampleRate: 0,
      sessionSampleRate: publicConfig.datadogRum.sampleRate,
      site: "datadoghq.com",
      trackUserInteractions: true,
      version,
    });
  });
};

export default datadogInit;
