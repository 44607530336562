"use client";

import { useMemo, useState } from "react";
import dynamic from "next/dynamic";

import Placeholder, { Rect } from "components/Placeholder";

type IconProps = { height?: string; width?: string; className?: string };

type Props = {
  filePath: string;
  height?: string;
  width?: string;
  className?: string;
  svgClassName?: string;
  testId?: string;
};

const IconSvg = ({
  filePath,
  height,
  width,
  className = "",
  svgClassName = "",
  testId = "svg-icon",
}: Props) => {
  const [error, setError] = useState(false);

  const iconProps: IconProps = {
    className: svgClassName,
    height: height,
    width: width,
  };

  const Icon = useMemo(
    () =>
      dynamic<IconProps>(
        () => import(`../Icons/${filePath}`).catch(() => setError(true)),
        {
          loading: () => (
            <Placeholder height={height} width={width}>
              <Rect height="100%" width="100%" />
            </Placeholder>
          ),
        },
      ),
    [filePath],
  );

  return !error ? (
    <span data-testid={testId} className={className}>
      <Icon {...iconProps} />
    </span>
  ) : (
    <></>
  );
};
export default IconSvg;
