import FacebookIcon from "components/Icons/share/facebook.svg";
import InstagramIcon from "components/Icons/share/instagram.svg";
import XIcon from "components/Icons/share/x.svg";
import YoutubeIcon from "components/Icons/share/youtube.svg";

import { FooterLink } from "./FooterLink";

const SocialIconLinks = () => {
  return (
    <>
      <FooterLink
        className="block px-md"
        href="https://www.facebook.com/share/129x8TLnkrZ/"
        title="Leafly on Facebook"
        trackingLabel="facebook"
      >
        <FacebookIcon width={24} height={24} />
      </FooterLink>
      <FooterLink
        className="block px-md"
        href="https://x.com/leafly"
        title="Leafly on X"
        trackingLabel="x(twitter)"
      >
        <XIcon width={24} height={24} />
      </FooterLink>
      <FooterLink
        className="block px-md"
        href="https://www.instagram.com/leafly?igsh=ZWtlOHhoYWgyejFo "
        title="Leafly on Instagram"
        trackingLabel="instagram"
      >
        <InstagramIcon width="18" height="18" />
      </FooterLink>
      <FooterLink
        className="block px-md"
        href="https://www.youtube.com/channel/UCqVnkns6Gf3W7J9zWZE4EqA"
        title="Leafly on YouTube"
        trackingLabel="youtube channel"
      >
        <YoutubeIcon width="21.86" height="14" />
      </FooterLink>
    </>
  );
};

export default SocialIconLinks;
