const logError = (
  /**
   * Required. Make this a simple but helpful message without any major differentiating
   * strings like IDs. This helps with grouping errors in Datadog.
   */
  message: string,
  info?: {
    /**
     * Optional. Use if making calls from a particular service.
     */
    service?:
      | "apiog"
      | "consumer"
      | "core-web-vitals"
      | "directus"
      | "finder"
      | "wordpress"
      | "compliance"
      | "google-maps"
      | "geocode"
      | "postalservice"
      | "reservations"
      | "usernotification"
      | "userprofile"
      | "user-history";
    /**
     * Optional. Use if receiving a status code from axios and/or a service.
     */
    statusCode?: string | number;
    /**
     * Optional. Defining a function name helps pinpoint errors more easily.
     */
    functionName?: string;
  },
) => {
  /**
   * if changing what is logged, the order of things being logged, etc. consider
   * also making the same change to the logError function in biz-biz
   * to maintain parity between the two, unless there is a good reason for them to diverge
   *
   * https://github.com/Leafly-com/biz-biz/blob/main/utils/logError.ts
   *
   **/

  /**
   * The vast majority of "network errors" are due to the user reloading or
   * navigating away from the page before the request completes. Generally,
   * this error indicates something outside of our control.
   * **/
  if (message.toLowerCase() !== "network error") {
    console.error(
      new Error(
        [info?.service, info?.functionName, info?.statusCode, message]
          .join(" ")
          .replace(/\s+/g, " ")
          .trim(),
      ),
    );
  }
};

export default logError;
