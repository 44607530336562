import { type AxiosError } from "axios";

import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";
import formatAndReturnRejectedError from "utils/formatAndReturnRejectedError";

// Initialize the service request with the app and environment
const postalServiceApi = serviceRequest();

// Configure and set upstream URL
postalServiceApi.defaults.baseURL = publicConfig.services.postalServiceApi.url;
postalServiceApi.defaults.withCredentials = true;

postalServiceApi.interceptors.response.use(
  (response) => response,
  (
    error: AxiosError<{
      message: string;
      errors: {
        path: string;
        message: string;
        errorCode?: string;
      }[];
    }>,
  ) =>
    formatAndReturnRejectedError(
      error,
      error.response?.data?.message ||
        (error.response?.data?.errors?.length
          ? error.response.data.errors[0].message
          : undefined),
      error.response?.data?.errors?.length
        ? error.response.data.errors[0].errorCode
        : undefined,
    ),
);

export default postalServiceApi;
