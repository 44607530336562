/**
 * Weedfetti is a component primarily rendered using a context. The `useWeedfetti`
 * hook provides a function called `launchWeedfetti`, which can be called anywhere
 * to launch a cascade of weed-shaped confetti across the page.
 *
 * This context requires the redux provider to be rendered higher up in the React
 * tree. In next.js applications, this is likely in the _app.js file, but could
 * also be in a shared layout component, if one exists. The `WeedfettiCannon` must
 * be rendered inside the redux provider as well.
 *
 * A minimal implementation would appear as shown below.
 *
 * In _app.js:
 *
 *   import { Provider } from "react-redux";
 *   import { WeedfettiCannon } from "components/Weedfetti";
 *
 *   class MyApp extends App {
 *     render() {
 *       const { Component, pageProps } = this.props;
 *
 *       return (
 *         <Provider>
 *           <Component {...pageProps} />
 *           <WeedfettiCannon />
 *         </Provider>
 *       )
 *     }
 *   }
 *
 * Somewhere else inside of the application:
 *
 *   function ConfettiButton() {
 *     const { launchWeedfetti } = useWeedfetti();
 *
 *     return (
 *       <button onClick={() => launchWeedfetti({ colors: ["#ff4", "#ae3"] })}>
 *         Launch
 *       </button>
 *     );
 *   }
 *
 */
export { useWeedfetti } from "./useWeedfetti";
export * from "./WeedfettiCannon";
