import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "redux/reducers/rootReducer";
import { parseFullUserName } from "utils/parseFullUserName";

export const getFullUser = (state: RootState) => state.user;

export const getUserId = createSelector([getFullUser], (user) => user.id);
export const getUserFavoriteStrains = createSelector(
  [getFullUser],
  (user) => user.favoritedStrains,
);
export const getUserStrainPreferences = createSelector(
  [getFullUser],
  (user) => user.strainPreferences,
);
export const getUserFollowDispensaries = createSelector(
  [getFullUser],
  (user) => user.followedDispensaries,
);
export const getUserSubmittedReviews = createSelector(
  [getFullUser],
  (user) => user.reviews,
);
export const getIsUserLoading = createSelector(
  [getFullUser],
  (user) => user.loading,
);
export const getIsLoggedIn = createSelector(
  [getFullUser],
  (user) => !!(user?.id && user?.id > 0),
);
export const getIsLoggedOutRedirect = createSelector(
  [getFullUser],
  (user) => user?.isLoggedOutRedirect,
);

export const getUserPrefersMedical = createSelector(
  [getFullUser],
  (user) => user.prefersMedical,
);

export const getUserFulfillmentPreference = createSelector(
  [getFullUser],
  (user) => user.fulfillmentPreference,
);

export const getUserMedCard = createSelector(
  [getFullUser],
  (user) => user.medCard,
);

const getUserFullName = createSelector(
  [getFullUser],
  (user) => (user && user.name) || "",
);

export const getUserNameParsed = createSelector(
  [getUserFullName],
  parseFullUserName,
);

export const getUserEmail = createSelector(
  [getFullUser],
  (user) => (user && user.email) || "",
);

export const getUserPhone = createSelector(
  [getFullUser],
  (user) => (user && user.phoneNumber) || "",
);

export const getUserDateOfBirth = createSelector(
  [getFullUser],
  (user) => (user && user.dateOfBirth) || "",
);
export const userGovernmentIdImageInfoSelector = createSelector(
  [getFullUser],
  (user) => user.governmentCardImageData,
);

export const userMedicalCardImageInfoSelector = createSelector(
  [getFullUser],
  (user) => user.medicalCardImageData,
);

export const getCardImageDataFromType = (type: "government" | "medical") => {
  return type === "government"
    ? userGovernmentIdImageInfoSelector
    : userMedicalCardImageInfoSelector;
};

export const getCardImageLoadingFromType = (type: "government" | "medical") =>
  createSelector([getCardImageDataFromType(type)], (data) => data?.loading);
