function storageAvailable(type: "localStorage" | "sessionStorage") {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  } catch (e: any) {
    return (
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export const availableStorage = () => {
  if (storageAvailable("localStorage")) {
    // Allows the data to persist thru browser/window closing
    return localStorage;
  } else if (storageAvailable("sessionStorage")) {
    // Allows the data to persist thru browser-tab (only) closing
    return sessionStorage;
  }

  // Unable to store data
  return false;
};
