import { useEffect, useMemo } from "react";

/**
 * Fixes iFrames inserted from 3rd party services that
 * omit accessibility attributes
 * https://dequeuniversity.com/rules/axe/3.5/document-title
 */
export const useIFrameAccessibilityFix = () => {
  const observer = useMemo(
    () =>
      typeof MutationObserver !== "undefined"
        ? new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.type === "childList") {
                mutation.addedNodes.forEach((node) => {
                  if (
                    node instanceof HTMLIFrameElement &&
                    node?.style?.display === "none" &&
                    (!node.title || node.title === "")
                  ) {
                    node.title = "No content";
                  }
                });
              }
            });
          })
        : null,
    [],
  );

  useEffect(() => {
    observer?.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }, []);
};
