"use client";
import { PropsWithChildren } from "react";
import classNames from "classnames";

import ClearIcon from "components/Icons/x.svg";

export const Popup: React.FC<
  PropsWithChildren<{
    className?: string;
    onClose: () => void;
    closeButtonLabel: string;
  }>
> = ({ children, closeButtonLabel, className, onClose }) => (
  <div
    className={classNames(
      "p-lg pr-xxl text-xs text-white bg-default rounded z-[2147483647] shadow-low",
      className,
    )}
  >
    <div>{children}</div>

    <button
      aria-label={closeButtonLabel}
      className="absolute m-sm right-0 text-white top-0"
      onClick={onClose}
    >
      <ClearIcon height="16" width="16" />
    </button>
  </div>
);
