import { type AxiosError } from "axios";

import serviceRequest from "api/serviceRequest";
import publicConfig from "config/public";
import formatAndReturnRejectedError from "utils/formatAndReturnRejectedError";

export const consumerApiErrorInterceptor = (
  error: AxiosError<{
    type: string; // not super helpful, adding just to type entire response
    title: string;
    error: string;
    status: number;
  }>,
) =>
  formatAndReturnRejectedError(
    error,
    (error.response?.data?.title &&
      error.response?.data?.error &&
      `${error.response.data.title} ${error.response.data.error}`) ||
      error.response?.data?.title ||
      error.response?.data?.error,
    error.response?.data?.status,
  );

// Initialize the service request with the app and environment
const consumerApi = serviceRequest();

// Configure and set upstream URL
consumerApi.defaults.baseURL = publicConfig.services.consumerApi.url;
consumerApi.defaults.withCredentials = true;

consumerApi.interceptors.response.use(
  (response) => response,
  consumerApiErrorInterceptor,
);

export default consumerApi;
